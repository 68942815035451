import createListDuck from 'utils/createListDuck';
import { getPath } from 'utils/duckTools';

const countriesDuck = createListDuck(
  'COUNTRIES_LIST',
  getPath(__filename),
);

export const actions = {
  ra: countriesDuck.actions,
};

export const selectors = countriesDuck.selectors;

export default countriesDuck.reducer;
