import createGenericDataDuck from 'utils/createGenericDataDuck';
import { getPath } from 'utils/duckTools';

export const { reducer, actions, selectors } = createGenericDataDuck(
  'GEOGRAPHY_SINGLE_REPORT_RATINGS',
  getPath(__filename),
);

export default reducer;

