import InlineSVG from 'components/general/InlineSVG/InlineSVG';
import Link from 'components/general/Link/Link';
import Placeholder from 'components/general/Placeholder/Placeholder';
import React from 'react';

const Island = ({ children }) => (
  <div className="island">
    <div className="island__box">
      <div className="island__logo">
        <Link to={{ type: 'DASHBOARD' }}>
          <Placeholder width={300} height={130}>
            <InlineSVG name="gsi-logo" />
          </Placeholder>
        </Link>
      </div>
      {children}
    </div>
  </div>
);

export default Island;
