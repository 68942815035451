import * as R from 'ramda';
import PDF from 'components/general/PDF/PDF';
import { compose, branch, nest, renderComponent } from 'recompose';
import withFormat from 'components/hoc/withFormat';

const withPDFView = PDFComponent => compose(
  withFormat(),
  branch(
    R.propEq('format', 'pdf'),
    renderComponent(
      nest(PDF, PDFComponent),
    ),
  ),
);

export default withPDFView;
