import createListDuck from 'utils/createListDuck';
import { getPath } from 'utils/duckTools';

const articlesDuck = createListDuck(
  'ARTICLES_INDEX_LIST',
  getPath(__filename),
);

export const actions = {
  ra: articlesDuck.actions,
};

export const selectors = articlesDuck.selectors;

export default articlesDuck.reducer;
