import * as R from 'ramda';
import api from 'server/api';
import reduxFetch from 'utils/reduxFetch';
import { actions } from './ducks/list.duck';
import getFilterValues from './selectors/getFilterValues';

const route = async (dispatch, getState) => {
  const state = getState();
  const values = getFilterValues(state);

  return dispatch(reduxFetch({
    fetch: api('geography', 'get'),
    filters: R.applySpec({
      type: R.always('country'),
      search: R.prop('search'),
    })(values),
    actions: actions.ra,
    perPage: 24,
  }));
};

export default route;

