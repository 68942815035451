import * as R from 'ramda';
import { postValidateEmail } from 'server/api';
import flashRedirect from 'state/actions/flashRedirect';
import getAccessToken from 'state/selectors/getAccessToken';
import {NOT_FOUND, redirect} from "redux-first-router";

const route = async (dispatch, getState) => {
  const state = getState();
  const code = R.path(['location', 'payload', 'code'], state);
  const auth = getAccessToken(state);

  const validateResp = await dispatch(
    postValidateEmail({
      validationCode: code,
    }),
  );

  if (validateResp.errors) {
    dispatch(
      redirect({ type: 'LOGIN' })
      // flashRedirect([
      //   {
      //     message: 'Failed to validate',
      //     type: 'danger',
      //     position: 'static',
      //   },
      // ], {
      //   type: 'LOGIN',
      // }),
    );
  } else {
    dispatch(
      flashRedirect([
        {
          message: auth ? 'Validation successful.' : 'Validation successful. Log-in below.',
          type: 'success',
          position: 'static',
        },
      ], {
        type: auth ? 'ACCOUNT_PROFILE' : 'LOGIN',
      }),
    );
  }
};

export default route;
