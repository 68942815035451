import React from 'react';
import FlashList from 'components/general/FlashList/FlashList';
import { FlashPropDef } from 'components/general/Flash/Flash';

const StickyFlashes: React.SFC<PropDef> = ({ flashes }) => (
  <div className="sticky-flashes">
    <FlashList flashes={flashes} autoDismiss />
  </div>
);

interface PropDef {
  flashes: FlashPropDef[];
}

export default StickyFlashes;

