import React from 'react';
import RenderPropType from 'utils/ts/RenderPropType';
import NotFound from 'components/pages/NotFound';

/**
 * Conditionally renders various page components based on the type passed down.
 */
const ComponentMap: React.SFC<ComponentMapPropDef> = ({
  page,
  components,
  isLoading,
  fallback,
  children,
}) => {
  const Component = components[page] || fallback;
  return (
    <Component isLoading={isLoading}>
      {children}
    </Component>
  );
};

ComponentMap.defaultProps = {
  fallback: NotFound,
};

interface ComponentMapPropDef {
  page: 'string';
  components: object;
  isLoading?: boolean;
  fallback?: RenderPropType;
}

export default ComponentMap;

