import 'core-js/es6/object';
import 'core-js/es6/symbol';
import 'core-js/es6/promise';
import 'core-js/es6/string';
import 'core-js/es6/array';
import 'core-js/es6/map';
import 'core-js/es6/weak-map';
import 'core-js/es6/number';
import 'core-js/es6/set';
import 'core-js/es7/array';
// Async/await (fast-async)
import 'nodent-runtime';
import 'picturefill';

// elem.matches fallback
if (!Element.prototype.matches) {
  Element.prototype.matches = Element.prototype.msMatchesSelector;
}
