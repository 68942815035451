import * as R from 'ramda';
import { connect } from 'react-redux';
import getFormat from 'selectors/getFormat';

const withFormat = () => connect(
  R.applySpec({
    format: getFormat,
  }),
);

export default withFormat;
