/**
 * queryToAPI
 * Converts generic url params to params for the API,
 * i.e. sort may be '_sort' in the mock.
 */
import * as R from 'ramda';

const mapping = {
  riskTypes: [{
    param: 'risktype.slug',
    value: val => (val ? val.join(',') : null),
  }],
  contentTags: [{
    param: 'contenttags.slug',
    value: val => (val ? val.join(',') : null),
  }],
  search: [{
    param: 'search',
    value: val => val,
  }],
  page: [
    {
      param: '_limit',
      value: R.prop('perPage'),
    },
    {
      param: 'offset',
      value: R.prop('offset'),
    },
  ],
  start: [{
    param: 'when_gte',
    value: val => val,
  }],
  end: [{
    param: 'when_lte',
    value: val => val,
  }],
  sort: [{
    param: 'sort',
    value: val => (
      val.by && val.order ? `${val.order === 'asc' ? '-' : ''}${val.by}` : null
    ),
  }],
  limit: [{
    param: '_limit',
    value: val => val,
  }],
};

const queryToAPI = (name, value) => {
  if (!mapping[name]) return { [name]: value };

  return mapping[name].reduce((obj, m) => (
    Object.assign({}, obj, {
      [m.param]: m.value(value),
    })
  ), {});
};

export default queryToAPI;
