import * as R from 'ramda';
import isServer from 'utils/isServer';

/**
 * Retrieves a config value from the global config.
 * @param path A Ramda-friendly path array.
 */
const config = (path: pathDef = []): any => R.pipe(
  R.when(
    R.is(String),
    p => ([p]),
  ),
  R.prepend('GSI_APP'),
  R.path<pathDef, string, any>(
    R.__,
    isServer ? global : window,
  ),
)(path);

type pathDef = (string|number)[] | string | number;

export default config;
