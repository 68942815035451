import { redirect } from 'redux-first-router';
import { addFlashes } from 'state/ducks/flashes';
import { FlashPropDef } from 'components/general/Flash/Flash';
import LinkProps from 'utils/ts/LinkProps';

/**
 * A redux-thunk creator that creates a 'flash' message (or messages) with a
 * cookie and redirects the user.
 * @param messages - Flash messages to add.
 * @param redirectObj - A redux-first-router link object.
 * @example
 * dispatch(
 *  flashRedirect([{
 *    message: 'Failed!',
 *    type: 'danger',
 *    position: 'static',
 *  }], { type: 'LOGIN' }),
 * )
 */
const flashRedirect = (messages: FlashPropDef[], redirectObj: LinkProps) => (
  (dispatch) => {
    dispatch(redirect(redirectObj));
    dispatch(addFlashes(messages));
  }
);

export default flashRedirect;

