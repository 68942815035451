import * as R from 'ramda';
import api from 'server/api';
import reduxFetch from 'utils/reduxFetch';
import { selectors as userSelectors } from 'ducks/user';
import { actions as globalAlertsActions } from './ducks/globalAlerts.duck';
import { actions as globalArticlesActions } from './ducks/globalArticles.duck';
import * as userAlerts from './ducks/userAlerts.duck';
import * as userArticles from './ducks/userArticles.duck';
import * as userRatings from './ducks/userRiskRatings.duck';

const route = async (dispatch, getState) => {
  const state = getState();
  const preferences = userSelectors.preferences(state) || {};

  // All user-pref based data
  const userThunks = R.pipe(
    R.when(
      R.always(R.has('dashboardRatings')),
      R.append({
        requestID: 'dashboardUserRatings',
        fetch: api('forecast', 'get'),
        actions: userRatings.actions,
        filters: R.pipe(
          R.prop('dashboardRatings'),
          R.applySpec({
            riskTypes: R.prop('riskTypes'),
            'geography.slug': R.tryCatch(
              R.pipe(
                R.prop('countries'),
                R.map(R.props(['value'])),
                R.join(','),
              ),
              R.always(null),
            ),
          }),
        )(preferences),
      }),
    ),
    R.when(
      R.always(R.has('dashboardAlerts', preferences)),
      R.append({
        requestID: 'dashboardUserAlerts',
        fetch: api('alert', 'get'),
        perPage: 8,
        actions: userAlerts.actions,
        filters: R.pipe(
          R.prop('dashboardAlerts'),
          R.applySpec({
            riskTypes: R.prop('riskTypes'),
            'geography.slug': R.tryCatch(
              R.pipe(
                R.prop('geographies'),
                R.map(R.props(['value'])),
                R.join(','),
              ),
              R.always(null),
            ),
          }),
        )(preferences),
      }),
    ),
    R.when(
      R.always(R.has('dashboardArticles', preferences)),
      R.append({
        requestID: 'dashboardUserArticles',
        fetch: api('article', 'get'),
        perPage: 6,
        actions: userArticles.actions,
        filters: R.pipe(
          R.prop('dashboardArticles'),
          R.applySpec({
            'geography.slug': R.tryCatch(
              R.pipe(
                R.prop('regions'),
                R.join(','),
              ),
              R.always(null),
            ),
          }),
        )(preferences),
      }),
    ),
    R.map(k => dispatch(reduxFetch(k))),
  )([]);

  // Fetch and set pref-based data
  const userData = await Promise.all(userThunks);


  // 'Global' views sans-filters, but excluding duplicates from user-data
  const globalThunks = [
    {
      fetch: api('alert', 'get'),
      perPage: 5,
      actions: globalAlertsActions,
    },
    {
      fetch: api('article', 'get'),
      perPage: 3,
      actions: globalArticlesActions,
    },
  ];

  const globalData = await Promise.all(R.pipe(
    R.map(k => dispatch(reduxFetch(k))),
    R.values,
  )(globalThunks));

  return Promise.resolve([userData, globalData]);
};

export default route;
