import React from 'react';
import * as R from 'ramda';
import track from 'react-tracking';
import { connect } from 'react-redux';
import { compose, withProps, withHandlers } from 'recompose';
import api from 'server/api';
import SearchBox from 'components/forms/SearchBox';
import withRenderPropsFactory from 'components/hoc/withRenderPropsFactory';
import reduxFetch from 'utils/reduxFetch';
import Media from 'components/general/Media/Media';

/**
 * Matches up entity types redux-first-router `Link` definitions.
 */
const entityRouteDef = {
  alerts: R.applySpec({
    type: R.always('ALERTS_SINGLE'),
    payload: {
      slug: R.prop('slug'),
    },
  }),
  articles: R.applySpec({
    type: R.always('ARTICLES_SINGLE'),
    payload: {
      slug: R.prop('slug'),
    },
  }),
  geography: R.applySpec({
    type: R.always('COUNTRIES_GEOGRAPHY'),
    payload: {
      geography: R.prop('slug'),
    },
  }),
};

/**
 * Retrieves the link object for a search result.
 */
const getLink = (item: resultDef) => {
  const def = R.prop(item.type, entityRouteDef);
  if (def) return def(item);
};

const SiteHeaderSearch = compose(
  track(),
  withHandlers({
    onBlur: ({ tracking }) => () => {
      // Fire search event. Seemed to be issues with reliability firing this 
      // on select.
      tracking.trackEvent({
        event: 'Used Search Bar',
      });
    },
    fetchFn: () => query => (
      reduxFetch({
        fetch: api('search', 'get'),
        filters: { q: query },
        requestID: 'siteHeaderSearch',
      })
    ),
    renderResult: () => (item: resultDef) => (
      <div>
        <Media
         title={item.title}
         small
         category={{
           title: item.type,
         }}
        />
      </div>
    ),
  }),
  withRenderPropsFactory({
    props: ['renderResult'],
  }),
  withProps({
    itemToString: R.prop('title'),
    inputClass: 'site-header__search-input',
    placeholder: 'Search',
    dimmer: true,
  }),
  connect(
    null,
    (dispatch, { tracking }) => ({
      onSelect: (item) => {
        if (!item) return null;
        // Navigate/select
        return dispatch(getLink(item));
      },
    }),
  ),
)(SearchBox);

interface resultDef {
  title: string;
  type: string;
  payload: object;
}

export default SiteHeaderSearch;
