/**
 * A placeholder block.
 */
import React from 'react';
import classNames from 'classnames';
import genWidth from 'utils/genWidth';

const Skeleton: React.SFC<SkeletonProps> = ({ types, width, unit }) => {
  const typeClasses = types.map(type => `skeleton--${type}`);
  const classes = classNames('skeleton', ...typeClasses);

  return (
    <div
      className={classes}
      style={
        width
          ? genWidth(width[0], width[1], unit)
          : null
      }
    />
  );
};

Skeleton.defaultProps = {
  types: [],
};

interface SkeletonProps {
  width?: [number,  number];
  types?: string[];
  unit?: 'px' | '%' | 'em' | 'rem';
}

export default Skeleton;
