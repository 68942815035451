/**
 * withOutsideClick
 */
import React from 'react';
import wrapDisplayName from 'recompose/wrapDisplayName';
import { findDOMNode } from 'react-dom';

const enhanceWithClickOutside = (Component) => {
  class EnhancedComponent extends React.PureComponent {
    constructor(props) {
      super(props);
      this.onClick = this.onClick.bind(this);
    }
    componentDidMount() {
      // Store a reference to the DOM elem so we can check later
      this.domNode = findDOMNode(this.instance);
      document.addEventListener('click', this.onClick, true);
    }
    componentWillUnmount() {
      document.removeEventListener('click', this.onClick, true);
    }
    onClick(e) {
      // If the target is within component, exit out
      if (this.domNode.contains(e.target)) return;
      // Run the wrappedComponent's handler
      if (this.wrappedComponent.handleClickOutside) {
        this.wrappedComponent.handleClickOutside();
      }
    }
    render() {
      return (
        <Component
          {...this.props}
          ref={
            c => {
              if (!c) return;
              // So we can look up a DOM reference
              this.instance = c;
              // If there is already a wrappedComponent,
              // we are chaining, so use that as the 'source'.
              this.wrappedComponent = c.wrappedComponent || c;
            }
          }
        />
      );
    }
  }

  EnhancedComponent.displayName = wrapDisplayName(Component, 'withClickOutside');

  return EnhancedComponent;
};

export default enhanceWithClickOutside;
