/**
 * Outputs React-Helmet metadata.
 */
import React from 'react';
import * as R from 'ramda';
import { createSelector } from 'reselect';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';

const HelmetMeta: React.SFC<MetaProps> = ({
  title,
  description,
  canonicalLink,
}) => (
  <Helmet>
    <title>{title}</title>
    {description && (
      <meta
        name="description"
        content={description}
      />
    )}
    {canonicalLink && (
      <link rel="canonical" href={canonicalLink} />
    )}
  </Helmet>
);

export interface MetaProps {
  title?: string;
  description?: string;
  canonicalLink?: string;
}

/**
 * Retrieves meta-data for Helmet meta tags, based on props and passed in state
 * slice. Prefers explicit props, then 'metaXXXXX' tags, then generic
 * best-guesses (e.g. 'title').
 */
const getMeta = createSelector(
  (state, ownProps) => R.pipe(
    // Apply the state-slice selector if passed in
    R.when(
      R.always(
        R.has('selector', ownProps),
      ),
      ownProps.selector,
    ),
    R.applySpec({
      title: R.pipe(
        R.ifElse(
          R.has('metaTitle'),
          // Prefer metaTitle
          R.prop('metaTitle'),
          // Fall back to generic title
          R.prop('title'),
        ),
      ),
      description: R.prop('metaDescription'),
      canonicalLink: R.prop('canonicalLink'),
    }),
  )(state),
  (_, ownProps) => R.applySpec({
    title: R.prop('title'),
    description: R.prop('description'),
  })(ownProps),
  (stateMeta, propMeta) => (
    R.merge(
      stateMeta,
      // Filter out nil values, or they'll merge over valid ones.
      R.reject(R.isNil, propMeta),
    )
  ),
);

const SEOMeta: React.SFC<MetaProps & {
  selector?(object);
}> = connect(getMeta)(HelmetMeta);

export default SEOMeta;

