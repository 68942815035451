import React from 'react';
import format from 'date-fns/format';

const CalendarDate: React.SFC<CalendarDatePropDef> = ({
  datetime,
  iso8601,
}) => {
  const parsedDate = new Date(iso8601);

  if (isNaN(parsedDate)) return null;

  return (
    <time className="calendar-date" dateTime={datetime}>
      <span className="calendar-date__month">
        {format(parsedDate, 'MMM')}
      </span>
      <span className="calendar-date__day">
        {parsedDate.getDate()}
      </span>
      <span className="calendar-date__year">
        {parsedDate.getFullYear()}
      </span>
    </time>
  );
};

interface CalendarDatePropDef {
  datetime: string;
  iso8601: string;
}

export default CalendarDate;
