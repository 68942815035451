import * as R from 'ramda';
import qs from 'qs';
import axios from 'axios';
import config from 'utils/config';

const qsOptions = { addQueryPrefix: true };

/**
 * fetchData options definition.
 */
export interface fetchDataOptions {
  // fetch?();
  baseURL?: string;
  id?: number | null;
  query?: object | null;
  body?: object | null;
  auth?: object | null;
  responseType?: string;
  cancelToken?: object | null;
}

/**
 * Full signature for fetchData (un-curried).
 */
export type fetchDataType = (
  method: string,
  path: string,
  options?: fetchDataOptions,
) => object;

/**
 * fetchData response definition.
 */
export interface fetchDataResponse {
  data: object;
  headers: object;
  ok: boolean;
}

const apiURL = config(['api', 'href']);

/**
 * Makes network requests to the API.
 * @param {string} method - The request method, e.g. 'GET'.
 * @param {string} path - The relative path to the endpoint (no starting slash)
 * e.g. 'geography'.
 * @param {number} id - A unique resource identifier.
 * @param {object} query - An object of query param key/vals.
 * @param {object} body - Any JSON to send in the response body.
 * @param {string} auth - The users's JWT auth token that we send to the API.
 */
const fetchData: fetchDataType = R.curry(
  async (method, path, options?: fetchDataOptions): Promise<fetchDataResponse> => {
    const {
      id,
      query,
      body,
      auth,
      responseType,
      cancelToken,
      baseURL = apiURL,
    } = options;

    // Typing issue fixed (but not released):
    // https://github.com/axios/axios/issues/1253
    return axios({
      url: `/${path}${id ? `/${id}` : ''}${query ? qs.stringify(query, qsOptions) : ''}`,
      baseURL,
      withCredentials: true,
      method,
      mode: 'cors',
      data: body || null,
      headers: auth ? {
        authorization: `Bearer ${auth}`,
      } : {},
      cancelToken,
      responseType: responseType || 'json',
    })
      .then(resp => ({
        data: resp.data,
        headers: resp.headers,
        ok: resp.status === 200,
        status: resp.status,
      }))
      .catch((err) => {
        // Try to return errors from the API
        const responseData = R.path(['response', 'data'], err);
        const responseStatus = R.path(['response', 'status'], err);

        return {
          data: responseData || {
            errors: [{
              type: err.__CANCEL__ ? 'cancel' : 'generic',
              message: err.message,
            }],
          },
          status: responseStatus,
        };
      });
  },
);

export default fetchData;

