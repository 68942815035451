import { compose, lifecycle, withHandlers } from 'recompose';

const getBody = (() => {
  let body;
  return () => {
    if (body) return body;
    return body = document.querySelector('body');
  };
})();

const setLocked = (locked, className) => {
  const body = getBody();
  body.classList[locked ? 'add' : 'remove'](className);
};

/**
 * Adds a class to body to lock scrolling. Passing in a function to lockOn will
 * let you change when the HOC should add the class in response to new props
 * (e.g. isOpen). This is optional, and using addLock/removeLock may be
 * preferable depending on the use-case.
 */
const withScrollLock = ({
  lockOn = () => false,
  lockClass = 'is-locked',
}: Options) => compose(
  withHandlers({
    updateLock: props => () => {
      setLocked(lockOn(props), lockClass);
    },
    removeLock: () => () => {
      setLocked(false, lockClass);
    },
    addLock: () => () => {
      setLocked(true, lockClass);
    },
  }),
  lifecycle({
    componentDidMount() {
      this.props.updateLock();
    },
    componentDidUpdate() {
      this.props.updateLock();
    },
    componentWillUnmount() {
      this.props.removeLock();
    },
  }),
);

interface Options {
  // Gets props, should return true/false for if the component should lock
  lockOn(props: object): boolean;
  lockClass?: string;
}

export default withScrollLock;
