/**
 * @todo Config/src conversion stuff etc should be hoc?
 * A bitmap image component that supports responsive
 * images and lazy-loading. Can be used with an explicit width,
 * i.e. embedded with a WYSIWYG, or normally inside a parent
 * that controls the width.
 */
import Placeholder from 'components/general/Placeholder/Placeholder';
import ImgixClient from 'imgix-core-js';
import React from 'react';
import { onlyUpdateForKeys } from 'recompose';
import config from 'utils/config';
import isServer from 'utils/isServer';

// Doesn't work with SSR
if (!isServer) {
  require('lazysizes');
  require('lazysizes/plugins/bgset/ls.bgset.min');
}

// Sets up Imgix for proxying images
const client = new ImgixClient({
  host: 'gsi-proxy.imgix.net',
  secureURLToken: config('ImgixClientToken'),
});

// Builds srcset string from sources
const createSrcSet = (crops, src) => (
  crops
    .map(source => (
      `${client.buildURL(src, {
        w: source.w,
        h: source.h,
        fit: (source.w && source.h) ? 'crop' : 'max',
        auto: 'format',
      })} ${source.w}w`
    ))
    .join(', ')
);

// Used as a placeholder so we can provide a valid src
const dummySrc = 'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==';

const Image: React.SFC<ImageProps> = ({
  src,
  srcset,
  crops,
  alt,
  width,
  className,
  lazyload,
  imgix,
  background,
  placeholder,
}) => {
  const srcSet = imgix ? createSrcSet(crops, src) : srcset;
  const imgProps = lazyload ? {
    className: 'lazyload',
    src: src,
    'data-srcset': srcSet,
    'data-sizes': 'auto',
  } : {
    src,
    srcset: srcSet,
  };

  const img = src ? (
    <img
      {...imgProps}
      alt={alt || ''}
      itemProp="image"
      itemScope
      itemType="https://schema.org/ImageObject"
    />
  ) : null;

  const image = placeholder ? (
    <Placeholder
      width={crops[0].w}
      height={crops[0].h}
      className={className}
      background={background}
    >
      {img}
    </Placeholder>
  ) : img;

  return image;
};

Image.defaultProps = {
  alt: '',
  width: 0,
  src: '',
  srcset: '',
  crops: [{
    w: 200,
    h: 200,
  }],
  lazyload: true,
  imgix: false,
  background: true,
  placeholder: true,
};

export interface ImageProps {
  src: string;
  srcset: string;
  crops: {
    w: number,
    h: number,
  }[];
  alt?: string;
  width?: number;
  className?: string;
  lazyload?: boolean;
  imgix?: boolean;
  background?: boolean;
  placeholder?: boolean;
}

export default onlyUpdateForKeys(['src'])(Image);
