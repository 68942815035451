import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Link, { NavLink } from 'redux-first-router-link';
import Icon from 'components/general/Icon/Icon';
import InlineSVG from 'components/general/InlineSVG/InlineSVG';
import Button from 'components/general/Button/Button';
import Placeholder from 'components/general/Placeholder/Placeholder';
import SiteHeaderSearch from 'containers/SiteHeaderSearch';
import DropdownMenu from 'components/general/DropdownMenu/DropdownMenu';
import withPopover from 'components/hoc/withPopover';

const UserProfile = withPopover((props) => {
  const {
    user,
    userLinks,
    isOpen,
    onClose,
    triggerProps,
  } = props;

  const btnClasses = classNames('site-header__user-trigger', {
    'site-header__user-trigger--is-active': isOpen,
  });

  return (
    <div className="site-header__user" data-test="site-header-profile">
      <button
        {...triggerProps}
        className={btnClasses}
        data-test="site-header__user-toggle"
      >
        <Icon name="user" types={['push-right']} />
        <span className="site-header__user-name">{user.name}</span>
        <Icon name="chevron-down" types={['push-left']} />
      </button>
      <DropdownMenu
        isOpen={isOpen}
        onSelect={onClose}
        className="site-header__user-menu"
        items={userLinks}
      />
    </div>
  );
}, { autoDismiss: true });

class Header extends React.Component {
  componentDidUpdate(prevProps) {
    if (this.props.siteSearchActive && !prevProps.siteSearchActive) {
      this.focusSearch();
    }
  }
  focusSearch() {
    this.searchInput.focus();
  }
  focusToggle() {
    this.searchToggle.focus();
  }
  render() {
    const {
      links,
      siteNavOpen,
      toggleSiteNav,
      siteSearchActive,
      toggleSearch,
      closeSearch,
      user,
      userLinks,
      marketingUrl,
    } = this.props;

    const mainClass = classNames('site-header', {
      'site-header--is-searching': siteSearchActive,
    });

    const navToggleClass = classNames('site-header__nav-toggle', {
      'site-header__nav-toggle--is-active': siteNavOpen,
    });

    const navClass = classNames('site-header__nav', {
      'site-header__nav--is-open': siteNavOpen,
    });

    return (
      <header className={mainClass}>
        {Boolean(links.length) && (
          <button className={navToggleClass} onClick={toggleSiteNav}>
            <InlineSVG name="bars-line" />
          </button>
        )}
        <Link className="site-header__branding" to={{ type: 'LOGIN' }}>
          <div className="site-header__logo site-header__logo--srm">
            <Placeholder width={300} height={130}>
              <InlineSVG name="srm-logo-white" />
            </Placeholder>
          </div>
          <div className="site-header__logo site-header__logo--gsi">
            <Placeholder width={300} height={130}>
              <InlineSVG name="gsi-logo" />
            </Placeholder>
          </div>
        </Link>
        <nav className={navClass}>
          <ul className="site-nav">
            {links.map(link => (
              <li
                key={`site-nav__item-${link.title}`}
                className="site-nav__item"
              >
                <NavLink
                  to={link.to}
                  className="site-nav__link"
                  activeClassName="site-nav__link--is-current"
                  exact={link.exact}
                >
                  {link.title}
                </NavLink>
              </li>
            ))}
          </ul>
        </nav>
        {user && (
          <Fragment>
            <div className="site-header__search">
              <Button className="site-header__search-dismiss" onClick={closeSearch}>
                <Icon name="chevron-left" />
              </Button>
              <SiteHeaderSearch inputRef={c => this.searchInput = c} />
            </div>
            <button
              onClick={toggleSearch}
              ref={c => this.searchToggle = c}
              className="site-header__btn-toggle site-header__btn-toggle--search"
            >
              <span>
                <Icon name="search" />
              </span>
            </button>
          </Fragment>
        )}
        {user ? (
          <UserProfile user={user} userLinks={userLinks} />
        ) : (
          <div className="site-header__actions">
            <Link
              className="site-header__btn-toggle site-header__login"
              to={{
                type: 'LOGIN',
              }}
            >
              <span>
                Sign in
              </span>
            </Link>
            <Button
              className="site-header__signup"
              types={['sm', 'accent']}
              to={marketingUrl}
            >
              Find out more
            </Button>
          </div>
        )}
      </header>
    );
  }
}

Header.propTypes = {
  links: PropTypes.array,
  siteNavOpen: PropTypes.bool.isRequired,
  toggleSiteNav: PropTypes.func.isRequired,
  siteSearchActive: PropTypes.bool.isRequired,
  toggleSearch: PropTypes.func.isRequired,
  marketingUrl: PropTypes.string.isRequired,
};

export default Header;
