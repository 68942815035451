import * as R from 'ramda';
import { withHandlers } from 'recompose';

const withRenderPropsFactory = (options = {}) => Component => (
  withHandlers(
    R.pipe(
      R.map(handler => ({
        [handler]: props => (item) => {
          if (R.has(handler, props) && R.is(Function, props[handler])) {
            return props[handler](item);
          } else if (R.is(String, item) || R.is(Number, item)) {
            return item;
          }

          console.error(`${Component.displayName} results must either be a string, or you must provide a renderResult prop.`);

          return null;
        },
      })),
      R.mergeAll,
    )(options.props),
  )(Component)
);

export default withRenderPropsFactory;
