import * as R from 'ramda';
import api from 'server/api';
import reduxFetch from 'utils/reduxFetch';
import getFilterValues from './shared/selectors/getFilterValues';
import { actions as globalRegionActions } from './ducks/regions.duck';
import { actions } from './ducks/list.duck';

const route = async (dispatch, getState) => {
  const state = getState();
  const values = getFilterValues(state);

  const thunks = [
    {
      fetch: api('geography', 'get'),
      actions: globalRegionActions,
      filters: {
        type: 'globalRegion',
        fields: 'slug,id,title,type',
      },
    },
    {
      fetch: api('article', 'get'),
      actions: actions.ra,
      perPage: 12,
      filters: R.applySpec({
        start: R.path(['dateRange', 'start']),
        end: R.path(['dateRange', 'end']),
        search: R.prop('search'),
        'editor.id': R.prop('author'),
        'geography.slug': R.ifElse(
          R.has('regions'),
          R.pipe(
            R.prop('regions'),
            R.join(','),
          ),
          R.always(null),
        ),
        sort: {
          by: R.prop('sort'),
          order: R.prop('order'),
        },
      })(values),
    },
  ];

  return Promise.all(
    R.pipe(
      R.map(k => dispatch(reduxFetch(k))),
      R.values,
    )(thunks),
  );
};

export default route;
