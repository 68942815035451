import * as R from 'ramda';
import { createSelector } from 'reselect';
import forecastRiskTypes from 'consts/forecastRiskTypes';
import createGenericDataDuck from 'utils/createGenericDataDuck';
import { getPath } from 'utils/duckTools';

/**
 * Forecast data is 'cached' by key (${slug}-${riskType}). The slug is necessary
 * since we're using the same parent for geography and region, meaning that if
 * both have 'War' they'll overlap - so we need to differentiate.
 */
const duck = createGenericDataDuck(
  'GEOGRAPHY_SINGLE_FORECAST',
  getPath(__filename),
  // Just store the content
  R.pipe(
    R.prop('content'),
    R.values,
    R.head,
  ),
  // Produce a unique key to cache by
  R.pipe(
    R.pick(['geography', 'content']),
    R.over(
      R.lensProp('geography'),
      R.prop('slug'),
    ),
    R.over(
      R.lensProp('content'),
      R.pipe(
        R.keys,
        R.head,
      ),
    ),
    R.values,
    R.join('-'),
  ),
);

const getRiskType = createSelector(
  R.path(['location', 'payload', 'riskType']),
  type => type || 'travelSummary',
);

const getSlug = createSelector(
  R.path(['location', 'payload', 'region']),
  R.path(['location', 'payload', 'geography']),
  (region, geog) => region || geog,
);

const getCurrentData = createSelector(
  getRiskType,
  duck.selectors.data,
  getSlug,
  (riskType, data, slug) => {
    // Convert the Slug in to an ID, since this is how it's stored in Redux.
    const typeID = R.pipe(
      R.find(
        R.propEq('slug', riskType),
      ),
      R.propOr('travelSummary', 'id'),
      risk => `${slug}-${risk}`,
    )(forecastRiskTypes);

    return R.prop(typeID, data) || {};
  },
);

export const selectors = {
  ...duck.selectors,
  getCurrentData,
};

export const actions = duck.actions;

export default duck.reducer;

