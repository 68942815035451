import * as R from 'ramda';
import { createSelector } from 'reselect';
import getSiteQuery from 'selectors/getSiteQuery';

const getFilterValues = createSelector(
  getSiteQuery,
  R.omit(['page', 'hideOnboardingModal']),
);

export default getFilterValues;
