const forecastRiskTypes: forecastType[] = [
  {
    id: 'travelSummary',
    title: 'Travel summary',
    riskType: 'riskOverall',
  },
  {
    id: 'kidnap',
    title: 'Kidnap for ransom',
    slug: 'kidnap-for-ransom',
    riskType: 'riskKidnapForRansom',
  },
  {
    id: 'extortion',
    title: 'Extortion',
    slug: 'extortion',
    riskType: 'riskExtortion',
  },
  {
    id: 'wrongfulDetention',
    title: 'Wrongful detention',
    slug: 'wrongful-detention',
    riskType: 'riskWrongfulDetention',
  },
  {
    id: 'politicalEvacuation',
    title: 'Political evacuation',
    slug: 'political-evacuation',
    riskType: 'riskPoliticalEvacuation',
  },
  {
    id: 'crime',
    title: 'Crime',
    slug: 'crime',
    riskType: 'riskCrime',
  },
  {
    id: 'war',
    title: 'War',
    slug: 'war',
    riskType: 'riskWar',
  },
  {
    id: 'terrorism',
    title: 'Terrorism',
    slug: 'terrorism',
    riskType: 'riskTerrorism',
  },
  {
    id: 'civilUnrest',
    title: 'Civil unrest',
    slug: 'civil-unrest',
    riskType: 'riskCivilUnrest',
  },
  {
    id: 'politicalViolence',
    title: 'Political violence',
    slug: 'political-violence',
    riskType: 'riskPoliticalViolence',
  },
];

export interface forecastType {
  id: string;
  title: string;
  riskType: string;
  slug?: string;
}

export default forecastRiskTypes;

