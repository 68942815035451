import React from 'react';
import Island from 'components/siteLayouts/Island';
import Flash from 'components/general/Flash/Flash';
import SEOMeta from 'containers/SEOMeta';

const NotFound = () => (
  <Island>
    <SEOMeta title="404" />
    <Flash
      icon="warning"
      type="accent"
      message="It looks like the page you're looking for doesn't exist, or it’s now inactive. Head back to the Homepage instead?"
      actions={[{
        title: 'Return home',
        types: ['accent'],
        to: {
          type: 'DASHBOARD',
        },
      }]}
    />
  </Island>
);

export default NotFound;
