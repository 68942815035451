import createTableDuck from 'utils/createTableDuck';
import { getPath } from 'utils/duckTools';

export const invitations = createTableDuck(
  'MANAGE_CUSTOMERS_CUSTOMER_INVITES',
  getPath(__filename),
);

export const { actions, reducer, selectors } = invitations;

export default reducer;

