import * as R from 'ramda';
import { createSelector } from 'reselect';
import { getOrigin } from 'ducks/origin';

/**
 * @todo Check if it makes a real url?
 */
const getCurrentPermalink = createSelector(
  getOrigin,
  R.path(['location', 'pathname']),
  R.path(['location', 'search']),
  (origin, path, search) => `${origin}${path}${search ? `?${search}` : ''}`,
);

export default getCurrentPermalink;

