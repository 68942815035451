import * as R from 'ramda';

const getResponseItems = (resp, prop = 'id') => R.pipe(
  R.prop('data'),
  R.map(R.prop(prop)),
  R.join(','),
)(resp);

export default getResponseItems;

