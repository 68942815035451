/**
 * UI
 * Global UI state
 */
import { combineReducers } from 'redux';

export const toggleNav = () => ({
  type: 'TOGGLE_NAV',
});

export const openNav = () => ({
  type: 'OPEN_NAV',
});

export const closeNav = () => ({
  type: 'CLOSE_NAV',
});

export const siteNavActive = (state = false, action) => {
  switch (action.type) {
    case 'TOGGLE_NAV':
      return !state;
    case 'OPEN_NAV':
      return true;
    case 'CLOSE_NAV':
      return false;
    default:
      return state;
  }
};

export const toggleSearch = () => ({
  type: 'TOGGLE_SEARCH',
});

export const openSearch = () => ({
  type: 'OPEN_SEARCH',
});

export const closeSearch = () => ({
  type: 'CLOSE_SEARCH',
});

export const siteSearchActive = (state = false, action) => {
  switch (action.type) {
    case 'TOGGLE_SEARCH':
      return !state;
    case 'OPEN_SEARCH':
      return true;
    case 'CLOSE_SEARCH':
      return false;
    default:
      return state;
  }
};

export default combineReducers({
  siteNavActive,
  siteSearchActive,
});
