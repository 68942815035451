/**
 * Route map
 * Each route with async dependencies correspond to a 'thunk',
 * an async redux action that the router waits for.
 * Routes can have a 'flag' property which matches up with a user-flag required
 * to access the route. Passing `true` instead will just check for
 * authentication (i.e. the existence of 'flags'), so can act as a
 * 'user-is-logged-in' check.
 */
import { redirect } from 'redux-first-router';
import dashboard from 'screens/dashboard/thunk';
import countriesIndex from 'screens/countries/thunk';
import countriesGeography from 'screens/countries/screens/single/thunk';
import countriesGeographyReport from 'screens/countries/screens/report/thunk';
import countriesComparison from 'screens/countries/screens/compare/thunk';
import alertsIndex from 'screens/alerts/thunk';
import alertsSingle from 'screens/alerts/screens/single/thunk';
import alertsIndexMap from 'screens/map/thunk';
import articlesIndex from 'screens/articles/thunk';
import articlesSingle from 'screens/articles/screens/single/thunk';
import bypassLoggedIn from 'state/routeThunks/account/bypassLoggedIn';
import manageUsers from 'screens/account/screens/organisation/screens/users/thunk';
import manageUsersSingle from 'screens/account/screens/organisation/screens/users/screens/single/thunk';
import manageInvitations from 'screens/account/screens/organisation/screens/invitations/thunk';
import logout from 'state/routeThunks/account/logout';
import validateEmail from 'state/routeThunks/account/validateEmail';
import acceptInvite from 'screens/acceptInvite/thunk';
import manageCustomers from 'screens/account/screens/organisation/screens/customers/thunk';
import manageCustomerSingle from 'screens/account/screens/organisation/screens/customers/screens/customer/thunk';
import manageCustomerSingleUsers from 'screens/account/screens/organisation/screens/customers/screens/customer/screens/users/thunk';
import manageCustomerSingleUsersSingle from 'screens/account/screens/organisation/screens/customers/screens/customer/screens/users/screens/single/thunk';
import manageCustomerSingleInvitations from 'screens/account/screens/organisation/screens/customers/screens/customer/screens/invitations/thunk';
import manageCustomerSingleInvitationsNew from 'screens/account/screens/organisation/screens/customers/screens/customer/screens/invitations/screens/create/thunk';
import manageCustomerSubscription from 'screens/account/screens/organisation/screens/customers/screens/customer/screens/subscription/thunk';
import preferencesInterests from 'screens/account/screens/preferences/screens/interests/thunk';
import manageSubscriptions from 'screens/account/screens/admin/screens/subscriptions/thunk';
import editSubscription from 'screens/account/screens/admin/screens/subscriptions/screens/single/thunk';
import info from 'screens/info/thunk';

const routes = {
  LOGIN: {
    path: '/',
    thunk: bypassLoggedIn,
  },
  LOGIN_LEGACY: {
    path: '/login',
    thunk: dispatch => dispatch(redirect({ type: 'LOGIN' })),
  },
  PRICING: {
    path: '/pricing',
  },
  DASHBOARD: {
    path: '/dashboard',
    thunk: dashboard,
    role: 'account',
    meta: {
      resource: 'Dashboard',
    },
  },
  COUNTRIES: {
    path: '/countries',
    thunk: countriesIndex,
    meta: {
      resource: 'Geography',
      paywall: true,
      paywallMessage: 'To access our in-depth country analysis, and further \
        tools to assess your security risks, subscribe to GSI.',
      paywallCTAEvent: 'Clicked Countries Find Out More button',
    },
  },
  COUNTRIES_GEOGRAPHY: {
    path: '/countries/:geography/:region?/summary/:riskType?',
    thunk: countriesGeography,
    meta: {
      resource: 'Geography',
      paywall: true,
      paywallMessage: 'To access our in-depth country analysis, and further \
        tools to assess your security risks, subscribe to GSI.',
    },
  },
  COUNTRIES_COMPARISON: {
    path: '/countries/compare',
    thunk: countriesComparison,
    meta: {
      resource: 'Geography',
      paywall: true,
      paywallMessage: 'To access our in-depth country analysis, and further \
        tools to assess your security risks, subscribe to GSI.',
    },
  },
  // PDF only
  COUNTRIES_REPORT: {
    path: '/countries/report/:geography',
    thunk: countriesGeographyReport,
    flag: 'PAID_DATA',
    meta: {
      resource: 'Geography',
      paywall: true,
      paywallMessage: 'To access our in-depth country analysis, and further \
        tools to assess your security risks, subscribe to GSI.',
    },
  },
  ALERTS_INDEX: {
    path: '/alerts',
    thunk: alertsIndex,
    meta: {
      resource: 'Alert',
      paywall: true,
    },
  },
  ALERTS_SINGLE: {
    path: '/alerts/:slug',
    thunk: alertsSingle,
    flag: true,
    meta: {
      resource: 'Alert',
      paywall: true,
    },
  },
  MAP: {
    path: '/map',
    thunk: alertsIndexMap,
    meta: {
      resource: 'Alert',
      feature: 'map',
      paywall: true,
      paywallMessage: 'To access our global risk maps, and further tools to \
        assess your security risks, subscribe to GSI.',
      paywallCTAEvent: 'Clicked Map Find Out More button',
    },
  },
  ARTICLES_INDEX: {
    path: '/articles',
    thunk: articlesIndex,
    meta: {
      resource: 'Article',
    },
  },
  ARTICLES_SINGLE: {
    path: '/articles/:slug',
    thunk: articlesSingle,
    meta: {
      resource: 'Article',
    },
  },
  ORGANISATION: {
    role: 'account',
    path: '/organisation',
    meta: {
      resource: 'Organisation',
    },
  },
  ORGANISATION_USERS: {
    role: 'account',
    path: '/organisation/users',
    thunk: manageUsers,
    meta: {
      resource: 'Organisation',
    },
  },
  ORGANISATION_USERS_SINGLE: {
    role: 'account',
    path: '/organisation/users/:userId',
    thunk: manageUsersSingle,
    meta: {
      resource: 'Organisation',
    },
  },
  ORGANISATION_INVITATIONS: {
    role: 'account',
    path: '/organisation/invitations',
    thunk: manageInvitations,
    meta: {
      resource: 'Organisation',
    },
  },
  ORGANISATION_INVITATIONS_CREATE: {
    role: 'account',
    path: '/organisation/invitations/new',
    meta: {
      resource: 'Organisation',
    },
  },
  ORGANISATION_ASSETS: {
    role: 'account',
    path: '/organisation/assets',
    meta: {
      resource: 'Organisation',
    },
  },
  ORGANISATION_CUSTOMERS: {
    role: 'account',
    path: '/organisation/customers',
    thunk: manageCustomers,
    meta: {
      resource: 'Customer',
    },
  },
  ORGANISATION_CUSTOMERS_CREATE: {
    role: 'account',
    path: '/organisation/customers/new',
    meta: {
      resource: 'Customer',
    },
  },
  ORGANISATION_CUSTOMERS_SINGLE: {
    role: 'account',
    path: '/organisation/customers/:customerId',
    thunk: manageCustomerSingle,
    meta: {
      resource: 'Customer',
    },
  },
  ORGANISATION_CUSTOMERS_SINGLE_USERS: {
    role: 'account',
    path: '/organisation/customers/:customerId/users',
    thunk: manageCustomerSingleUsers,
    meta: {
      resource: 'Customer',
    },
  },
  ORGANISATION_CUSTOMERS_SINGLE_USERS_SINGLE: {
    role: 'account',
    path: '/organisation/customers/:customerId/users/:userId',
    thunk: manageCustomerSingleUsersSingle,
    meta: {
      resource: 'Customer',
    },
  },
  ORGANISATION_CUSTOMERS_SINGLE_INVITATIONS: {
    role: 'account',
    path: '/organisation/customers/:customerId/invitations',
    thunk: manageCustomerSingleInvitations,
    meta: {
      resource: 'Customer',
    },
  },
  ORGANISATION_CUSTOMERS_SINGLE_INVITATIONS_CREATE: {
    role: 'account',
    path: '/organisation/customers/:customerId/invitations/new',
    thunk: manageCustomerSingleInvitationsNew,
    meta: {
      resource: 'Customer',
    },
  },
  ORGANISATION_CUSTOMERS_SINGLE_ASSETS: {
    role: 'account',
    path: '/organisation/customers/:customerId/assets',
    meta: {
      resource: 'Customer',
    },
  },
  ORGANISATION_CUSTOMERS_SUBSCRIPTION: {
    role: 'account',
    path: '/organisation/customers/:customerId/subscription',
    thunk: manageCustomerSubscription,
    meta: {
      resource: 'Subscription',
      // feature: 'subscription',
    },
  },
  ADMIN_SUBSCRIPTIONS: {
    role: 'account',
    path: '/admin/subscriptions',
    thunk: manageSubscriptions,
    meta: {
      resource: 'Subscription',
    },
  },
  ADMIN_SUBSCRIPTIONS_CREATE: {
    role: 'account',
    path: '/admin/subscriptions/new',
    meta: {
      resource: 'Subscription',
    },
  },
  ADMIN_SUBSCRIPTIONS_SINGLE: {
    role: 'account',
    path: '/admin/subscriptions/:subscriptionId',
    thunk: editSubscription,
    meta: {
      resource: 'Subscription',
    },
  },
  PREFERENCES: {
    role: 'account',
    path: '/preferences',
    meta: {
      resource: 'Preferences',
    },
  },
  PREFERENCES_INTERESTS: {
    role: 'account',
    path: '/preferences/interests',
    thunk: preferencesInterests,
    meta: {
      resource: 'Preferences',
    },
  },
  PREFERENCES_EMAILS: {
    role: 'account',
    path: '/preferences/emails',
    meta: {
      resource: 'Preferences',
    },
  },
  FORGOT_PASSWORD: {
    path: '/login/forgot',
    meta: {
      feature: 'login',
    },
  },
  RESET_PASSWORD: {
    path: '/login/reset/:code',
  },
  REGISTER_BUSINESS_INTEREST: {
    path: '/register-business-interest',
  },
  LOGOUT: {
    path: '/logout',
    thunk: logout,
    meta: {
      resource: 'Preferences',
    },
  },
  ACCEPT_INVITE: {
    path: '/accept-invite/:code',
    thunk: acceptInvite,
  },
  VALIDATE_EMAIL: {
    path: '/validate-email/:code',
    thunk: validateEmail,
  },
  INFO: {
    path: '/info/:path+',
    thunk: info,
    meta: {
      resource: 'Info',
    },
  },
  FORBIDDEN: {
    path: '/forbidden',
  },
};

export interface routeMetaDef {
  // Primary entity for route, e.g. 'Alert'
  resource?: string;
  // The primary action on the entity for the route
  action?: 'update' | 'manage' | 'edit' | 'read' | 'delete' | 'create';
  // Display/feature options relating to the route, e.g.
  // map display on Alerts.
  feature?: string;
  // Message displayed if user can't access
  paywallMessage?: string;
  // Fired on click of 'find out more'
  paywallCTAEvent?: string;
}

export default routes;
