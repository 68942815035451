import * as R from 'ramda';
import { combineReducers } from 'redux';
import auth from 'utils/auth';
import api from 'server/api';
import { redirect } from 'redux-first-router';
import reduxFetch from 'utils/reduxFetch';
import { createReducer, createAction } from 'redux-act';

// @todo Use redux-act
// @todo Extract logic elsewhere?

/**
 * Actions
 */
export const loginRequest = data => ({
  data,
  type: 'LOGIN_REQUEST',
});

export const loginSuccess = ({ idToken, accessToken }, userData, preferencesData) => ({
  idToken,
  accessToken,
  userData,
  payload: { preferencesData },
  type: 'LOGIN_SUCCESS',
});

export const loginError = message => ({
  message,
  type: 'LOGIN_FAILURE',
});

export const updateData = userData => ({
  type: 'UPDATE_USER_DATA',
  payload: {
    userData,
  },
});

export const updatePreferences = createAction<object>('UPDATE_USER_PREFERENCES');

export const fetchData = () => (
  async dispatch => (
    dispatch(reduxFetch({
      fetch: api('user/whoami', 'get'),
      actions: {
        success: resp => (
          updateData(
            R.path(['payload', 'data'], resp),
          )
        ),
      },
    }))
  )
);

export const fetchPreferences = (id: number) => (
  async dispatch => (
    dispatch(reduxFetch({
      id,
      fetch: api('preference', 'get'),
      actions: {
        success: resp => (
          updatePreferences(
            R.path(['payload', 'data'], resp),
          )
        ),
      },
    }))
  )
);

export const login = data => (
  async (dispatch) => {
    const { email, password } = data;

    // Update state to indicate we're trying to login
    dispatch(loginRequest(data));

    try {
      // @todo Way to use reduxThunk for these?
      // Actually try to login
      const authData = await auth.login(email, password);
      const userData = await api('user/whoami', 'get')({
        auth: authData.accessToken,
      });

      // If we can't log in, return an error
      if (!userData.data) return 'Could not fetch user information. Please try again later.';

      // Set the session/cookie
      auth.setSession(authData);

      // @todo This is duplicated in configureStore
      const preferencesData = await api('preference', 'get')({
        id: R.prop('id', userData.data),
        auth: authData.accessToken,
      });

      // Update state to indicate success
      dispatch(loginSuccess(authData, userData.data, preferencesData.data));
      dispatch(redirect({ type: 'DASHBOARD' }));
    } catch (e) {
      dispatch(loginError(e.description));
      return e.description;
    }
  }
);

export const logout = () => (
  async (dispatch) => {
    // Delete the cookie/session data. Only applies to client-side
    // render, but doesn't break SSR. Cookie deletion is handled by
    // express for SSR (server/configureStore).
    auth.deleteSession();
    dispatch(redirect({ type: 'LOGIN' }));
  }
);

/**
 * Selectors
 */
export const selectors = {
  data: R.path(['user', 'data']),
  preferences: R.path(['user', 'preferences']),
};

/**
 * Reducers
 */
const defaultStatusState = {
  loading: false,
  authenticated: false,
};

const status = (state = defaultStatusState, action) => {
  switch (action.type) {
    case 'LOGIN_REQUEST':
      return Object.assign({}, state, {
        loading: true,
      });
    case 'LOGIN_SUCCESS':
      return Object.assign({}, state, {
        loading: false,
        authenticated: true,
      });
    case 'LOGIN_FAILURE':
      return Object.assign({}, state, {
        loading: false,
        authenticated: false,
      });
    case 'LOGOUT':
      return defaultStatusState;
    default:
      return state;
  }
};

const data = (state = null, action) => {
  switch (action.type) {
    case 'UPDATE_USER_DATA':
      return R.path(['payload', 'userData'], action);
    case 'LOGIN_SUCCESS':
      return action.userData;
    case 'LOGOUT':
      return null;
    default:
      return state;
  }
};

const preferences = createReducer({}, {});

const formatPreferences: (type: object) => object = R.pipe(
  R.indexBy(R.prop('key')),
  R.map(R.prop('value')),
);

preferences.on(updatePreferences, (_, payload) => (
  formatPreferences(payload)
));

preferences.on('LOGIN_SUCCESS', (_, payload) => (
  formatPreferences(R.prop('preferencesData', payload))
));

export default combineReducers({
  status,
  data,
  preferences,
});
