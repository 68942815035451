import * as R from 'ramda';
import { redirect, NOT_FOUND } from 'redux-first-router';
import api from 'server/api';
import reduxFetch from 'utils/reduxFetch';
import getPayload from 'selectors/getPayload';
import { actions as pageActions } from './ducks/page.duck';
import { actions as treeActions } from './ducks/tree.duck';

const route = async (dispatch, getState) => {
  const state = getState();
  const payload = getPayload(state);
  const path = R.prop('path', payload);

  // Redirect top level to About section
  // @todo Presumes existence of page, would be better to get first 'child'
  if (!path) {
    dispatch(redirect({
      type: 'INFO',
      payload: {
        path: 'about-gsi',
      },
    }));
  }

  const thunks = [
    {
      fetch: api('page', 'get'),
      filters: { path: `/${path}` },
      actions: pageActions,
      onError: () => dispatch(redirect({ type: NOT_FOUND })),
      onSuccess: (resp) => {
        const data = R.path(['data', '0'], resp);

        if (!data) {
          return dispatch(redirect({ type: NOT_FOUND }));
        }
      },
    },
    {
      fetch: api('page', 'get'),
      filters: { mode: 'menu' },
      actions: treeActions,
      onError: () => dispatch(redirect({ type: NOT_FOUND })),
    },
  ];

  return Promise.all(
    R.pipe(
      R.map(k => dispatch(reduxFetch(k))),
      R.values,
    )(thunks),
  );
};

export default route;
