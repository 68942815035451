import * as R from 'ramda';
import api from 'server/api';
import flashRedirect from 'state/actions/flashRedirect';
import getAccessToken from 'state/selectors/getAccessToken';
import reduxFetch from 'utils/reduxFetch';
import { addData } from 'ducks/invite';

const route = async (dispatch, getState) => {
  const state = getState();
  const code: string = R.path(['location', 'payload', 'code'], state);
  const auth = getAccessToken(state);

  if (auth) {
    return dispatch(
      flashRedirect(
        [{
          message: 'You cannot accept an invitation while logged in.',
          type: 'warning',
        }],
        {
          type: 'ACCOUNT_USERS',
        },
      ),
    );
  }

  return dispatch(
    reduxFetch({
      fetch: api('invitation', 'get'),
      id: code,
      onSuccess: (resp) => {
        dispatch(addData(resp.data));
      },
      onError: (resp) => {
        dispatch(
          flashRedirect(
            [
              {
                message: R.pipe(
                  R.path(['data', 'errors']),
                  R.map(R.prop('message')),
                  R.join(' '),
                )(resp),
                type: 'danger',
                position: 'static',
              },
            ],
            {
              type: 'LOGIN',
            },
          ),
        );
      },
    }),
  );
};

export default route;
