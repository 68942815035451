/**
 * elementIDCreator
 * https://hackernoon.com/10-react-mini-patterns-c1da92f068c5
 */

let count = 1;

export const resetID = () => {
  count = 1;
};

export const getNextID = () => (
  `element-id-${count++}`
);
