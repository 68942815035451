/**
 * invite
 */
import * as R from 'ramda';
import { createSelector } from 'reselect';

/**
 * Actions
 */
export const addData = data => ({
  type: 'ADD_INVITE_DATA',
  data,
});

export const clearData = () => ({
  type: 'CLEAR_INVITE_DATA',
});

/**
 * Selectors
 */
export const getData = createSelector(
  R.prop('invite'),
  user => user,
);

/**
 * Reducer
 */
const invite = (state = {}, action) => {
  switch (action.type) {
    case 'ADD_INVITE_DATA':
      return action.data;
    case 'CLEAR_DATA':
      return {};
    default:
      return state;
  }
};

/**
 * Full reducer
 */
export default invite;
