/**
 * SiteSettings
 * Global site settings
 */
import * as R from 'ramda';
import api from 'server/api';
import reduxFetch from 'utils/reduxFetch';
import createGenericDataDuck from 'utils/createGenericDataDuck';

export const settingsDuck = createGenericDataDuck(
  'SITE_SETTINGS',
  ['settings'],
);

export const load = () => (
  dispatch => (
    dispatch(
      reduxFetch({
        fetch: api('settings', 'get'),
        actions: settingsDuck.actions,
      }),
    )
  )
);

export const actions = R.merge(
  { load },
  settingsDuck.actions,
);

export const selectors = settingsDuck.selectors;

export default settingsDuck.reducer;
