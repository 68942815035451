import { ReactElement } from 'react';
import RenderPropType from 'utils/ts/RenderPropType';

const handleRenderProp = (
  renderProp: string|RenderPropType|ReactElement<any>,
  args?: object,
) => {
  if (!renderProp) return null;
  if (typeof renderProp === 'function') return renderProp(args);
  return renderProp;
};

export default handleRenderProp;

