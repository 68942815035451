import * as R from 'ramda';
import createGenericDataDuck from 'utils/createGenericDataDuck';
import { getPath } from 'utils/duckTools';

export const { reducer, actions, selectors } = createGenericDataDuck(
  'MAP_ALERTS_DETAIL',
  getPath(__filename),
  undefined,
  R.prop('id'),
);

export default reducer;

