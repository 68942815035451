import React from 'react';
import track from 'react-tracking';
import wrapDisplayName from 'recompose/wrapDisplayName';
import createErrorEvent from 'utils/createErrorEvent';

const withError = (Component) => {
  @track({})
  class EnhancedComponent extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        hasError: false,
      };
    }
    /**
     * Notify react-tracking of the error.
     * Doesn't use decorators due to issues with async/await (for Stacktrace)
     * and decorators.
     */
    async recordError(error, info) {
      const { tracking } = this.props;
      const event = await createErrorEvent(error, info);
      // If we pass just an error through, react-tracking will ignore it
      tracking.trackEvent(event);
    }
    componentDidCatch(error, info) {
      this.recordError(error, info);
      this.setState({ hasError: true });
    }
    render() {
      return <Component {...this.props} hasError={this.state.hasError} />;
    }
  }

  EnhancedComponent.displayName = wrapDisplayName(Component, 'withError');

  return EnhancedComponent;
};

export default withError;
