import * as R from 'ramda';
import { redirect, NOT_FOUND } from 'redux-first-router';
import { fetchAlerts } from 'server/api';
import reduxFetch from 'utils/reduxFetch';
import getResponseItems from 'utils/getResponseItems';
import { actions as articleActions } from './ducks/article.duck';
import { actions as relatedActions } from './ducks/related.duck';

const route = async (dispatch, getState) => {
  const state = getState();
  const slug = R.path(['location', 'payload', 'slug'], state);

  const alertData = await dispatch(
    reduxFetch({
      actions: articleActions,
      fetch: fetchAlerts,
      filters: { slug },
      onSuccess: (resp) => {
        const data = R.path(['data', '0'], resp);

        if (!data) {
          return dispatch(redirect({ type: NOT_FOUND }));
        }

        // Redirect users if the data is hidden
        if (data.isHidden) {
          dispatch(redirect({ type: 'ALERTS_INDEX' }));
        }
      },
    }),
  );

  // If we don't have a successful resp, just return without fetching related
  if (alertData.status === 401) {
    return alertData;
  }

  const relatedData = await dispatch(
    reduxFetch({
      fetch: fetchAlerts,
      actions: relatedActions,
      filters: {
        limit: 3,
        'exclude.slug': getResponseItems(alertData, 'slug'),
        'geography.slug': R.path(['data', '0', 'geography', 'slug'], alertData),
      },
    }),
  );

  return Promise.all([alertData, relatedData]);
};

export default route;
