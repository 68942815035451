import React from 'react';
import RFRLink from 'redux-first-router-link';
import toUrl from 'utils/toUrl';
import LinkProps from 'utils/ts/LinkProps';

// Check if this is an absolute url
const isAbsolute = link => /^https?:\/\//.test(link);

const Link: React.SFC<LinkElemPropDef> = (props) => {
  const { to, className, newTab, children } = props;

  if (newTab || isAbsolute(to)) {
    const href = toUrl(to);

    return (
      <a
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        className={className}
      >
        {children}
      </a>
    );
  }

  // Assume internal, so handled by router
  return (
    <RFRLink {...props}>
      {children}
    </RFRLink>
  );
};

interface LinkElemPropDef {
  to: LinkProps|string;
  newTab?: boolean;
  className?: string;
}

export default Link;
