import React from 'react';
import classNames from 'classnames';
import withPortal from 'components/hoc/withPortal';
import withScrollLock from 'components/hoc/withScrollLock/withScrollLock';
import Dimmer from 'components/general/Dimmer/Dimmer';
import ModalDialog from 'components/general/Modal/ModalDialog';
import { compose, onlyUpdateForKeys } from 'recompose';

// Modals are rendered in a portal, but events propagate up as if they were not.
// This means the parent click events will fire, so we have to stop it.
const stopPropagation = e => e.stopPropagation();

const Modal: React.SFC<ModalPropDef> = ({
  isOpen,
  onClose,
  children,
}) => (
    <div
      className={classNames('modal', {
        'modal--is-open': isOpen,
      })}
      onClick={stopPropagation}
    >
      <Dimmer
        className="modal__inner"
        isActive={isOpen}
      >
        <ModalDialog onClose={onClose}>
          {children}
        </ModalDialog>
      </Dimmer>
    </div>
  );

interface ModalPropDef {
  isOpen: boolean;
  onClose();
}

Modal.defaultProps = {
  isOpen: false,
};

export default compose(
  withPortal({ selector: '#modals' }),
  withScrollLock({
    lockOn: ({ isOpen }: ModalPropDef) => isOpen,
    lockClass: 'u-no-overflow',
  }),
  onlyUpdateForKeys(['isOpen']),
)(Modal);
