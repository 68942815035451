import * as R from 'ramda';
import Cookies from 'js-cookie';
import auth0 from 'auth0-js';
import config from 'utils/config';

const auth = (() => {
  const authConf = config('auth');

  const webAuth = new auth0.WebAuth({
    domain: R.prop('domain', authConf),
    clientID: R.prop('clientId', authConf),
    audience: R.prop('audience', authConf),
    responseType: 'token id_token',
    scope: 'openid email profile',
  });

  const isAuthenticated = auth => {
    return true;
    // Check whether the current time is past the
    // access token's expiry time
    // const expiresAt = JSON.parse(localStorage.getItem('expires_at'));
    // return new Date().getTime() < expiresAt;
  };

  const setSession = (authData) => {
    const {
      idToken,
      accessToken,
      expiresIn,
    } = authData;

    Cookies.set('auth', {
      idToken,
      accessToken,
    }, {
      secure: window.location.protocol === 'https:',
      // httpOnly: true,
      // expires: expiresIn / 86400,
    });
  };

  const login = (username, password) => (
    new Promise((resolve, reject) => {
      webAuth.client.login(
        {
          realm: 'Username-Password-Authentication',
          username,
          password,
        },
        (err, authResult) => {
          if (err) {
            console.error(err);
            reject(err);
          }
          resolve(authResult);
        },
      );
    })
  );

  const deleteSession = () => {
    Cookies.remove('auth');
  };

  return {
    login,
    isAuthenticated,
    setSession,
    deleteSession,
  };
})();

export default auth;
