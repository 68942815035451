import createListDuck from 'utils/createListDuck';
import { getPath } from 'utils/duckTools';

export const { selectors, actions, reducer } = createListDuck(
  'DASHBOARD_USER_ALERTS',
  getPath(__filename),
);

export default reducer;

