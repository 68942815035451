import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import {
  compose,
  withHandlers,
  branch,
  renderNothing,
  withStateHandlers,
} from 'recompose';
import Modal from 'components/general/Modal/Modal';
import SubHeader from 'components/general/SubHeader/SubHeader';
import Link from 'components/general/Link/Link';
import Can, { Cannot } from 'containers/Can/Can';
import reduxFetch from 'utils/reduxFetch';
import api from 'server/api';
import getUserData from 'selectors/getUserData';
import getUserPreferences from 'selectors/getUserPreferences';
import getUserAbility from 'selectors/getUserAbility';
import Button from 'components/general/Button/Button';
import { fetchPreferences } from 'ducks/user';
import getSiteQuery from 'selectors/getSiteQuery';

const OnboardingModal: React.SFC<PropDef> = ({ isOpen, onClose }) => (
  <Modal onClose={onClose} isOpen={isOpen}>
    <SubHeader title="Welcome to S-RM's Global Security Insight" />
    <div className="rich-text">
      {/* Free user */}
      <Cannot action="use" feature="paidData">
        <p>
          We’re here to provide you with a fresh perspective on the security
          risks affecting you, your work and your travel.
        </p>
        <p>
          To get started, make sure you{' '}
          <Link newTab to={{ type: 'PREFERENCES_INTERESTS', query: { hideOnboardingModal: true } }}>
            customise your daily risk digest e-mail
          </Link>{' '}
          so we send you the information that matters to you.
        </p>
        <p>
          After that, try searching our historical{' '}
          <Link newTab to={{ type: 'ALERTS_INDEX', query: { hideOnboardingModal: true } }}>
            alerts database
          </Link>, or browsing through our{' '}
          <Link newTab to={{ type: 'ARTICLES_INDEX', query: { hideOnboardingModal: true } }}>
            articles
          </Link>{' '}
          for our take on the latest global developments.
        </p>
        <p>
          You are on GSI’s Free plan. To enquire about GSI for Business, or for
          any further questions, contact{' '}
          <a href="mailto:gsi@s-rminform.com">gsi@s-rminform.com</a> and our
          team will be happy to help.
        </p>
      </Cannot>
      {/* Subscription user */}
      <Can action="use" feature="paidData">
        <p>
          We’re here to provide you with a fresh perspective on the security
          risks affecting you, your work and your travel.
        </p>
        <p>
          To get started, make sure you{' '}
          <Link newTab to={{ type: 'PREFERENCES_INTERESTS', query: { hideOnboardingModal: true } }}>
            customise your risk dashboard and daily risk digest e-mail
          </Link>{' '}
          so we send you the information that matters to you.
        </p>
        <p>After that, why not try:</p>
        <ul>
          <li>
            <Link newTab to={{ type: 'ALERTS_INDEX', query: { hideOnboardingModal: true } }}>
              Searching our historical alerts database
            </Link>
          </li>
          <li>
            <Link newTab to={{ type: 'MAP', query: { hideOnboardingModal: true } }}>
              Exploring our global risk maps, and using the filters to see a
              different view of the world
            </Link>
          </li>
          <li>
            <Link newTab to={{ type: 'COUNTRIES', query: { hideOnboardingModal: true } }}>
              Consulting our country pages for more in-depth analysis
            </Link>
          </li>
          <li>
            <Link newTab to={{ type: 'ARTICLES_INDEX', query: { hideOnboardingModal: true } }}>
              Browsing our Articles for our opinions on the latest global
              developments
            </Link>
          </li>
        </ul>
        <p>
          To get in touch with S-RM, contact{' '}
          <a href="mailto:gsi@s-rminform.com">gsi@s-rminform.com</a> and our
          team will be happy to help.
        </p>
      </Can>
      <Button onClick={onClose} types={['accent']}>
        Get started
      </Button>
    </div>
  </Modal>
);

interface PropDef {
  isOpen: boolean;
  onClose();
}

export default compose(
  // Redux state and preferences setting handler
  connect(
    // We need the ID to set preferences
    R.applySpec({
      ability: getUserAbility,
      userId: R.pipe(getUserData, R.unless(R.isNil, R.prop('id'))),
      preferences: getUserPreferences,
      hideOnboardingModal: R.pipe(
        getSiteQuery,
        R.prop('hideOnboardingModal'),
      ),
    }),
    dispatch => ({
      // Pass down a function to set the onboarding as dismissed
      setDismissed: userId =>
        dispatch(
          reduxFetch({
            fetch: api('preference', 'patch'),
            id: `${userId}/onboarding`,
            body: {
              dismissed: true,
            },
            onSuccess: () => dispatch(fetchPreferences(userId)),
          }),
        ),
    }),
  ),
  branch(
    R.anyPass([
      // Render nothing if already dismissed
      R.pathEq(['preferences', 'onboarding', 'dismissed'], true),
      // Render nothing if not logged in
      ({ ability }) => ability.can('use', 'login'),
      // If we have a query parameter set to hide the modal
      R.propEq('hideOnboardingModal', 'true'),
    ]),
    renderNothing,
  ),
  // Show/hide visibilty state
  withStateHandlers(
    { isOpen: true },
    {
      closeModal: () => () => ({
        isOpen: false,
      }),
    },
  ),
  withHandlers({
    onClose: ({ setDismissed, userId, flags, closeModal }) => () => {
      // Hide modal on-screen (will just not render next time)
      closeModal();
      // Save preference
      setDismissed(userId, flags);
    },
  }),
)(OnboardingModal);
