import * as R from 'ramda';
import { createSelector } from 'reselect';
import { selectors } from 'state/ducks/settings';

const getMarketingUrl = createSelector(
  selectors.data,
  R.propOr(
    'https://www.s-rminform.com',
    'marketingUrl',
  ),
);

export default getMarketingUrl;
