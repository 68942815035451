import createGenericDataDuck from 'utils/createGenericDataDuck';
import { getPath } from 'utils/duckTools';

export const { reducer, actions, selectors } = createGenericDataDuck(
  'INFO_PAGE_TREE',
  getPath(__filename),
);

export default reducer;

