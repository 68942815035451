import React from 'react';
import classNames from 'classnames';
import Link from 'components/general/Link/Link';
import InlineSVG from 'components/general/InlineSVG/InlineSVG';
import LinkProps from 'utils/ts/LinkProps';

const Badge: React.SFC<BadgePropDefs> = ({
  children,
  className,
  types,
  onClick,
  to,
}) => {
  const Wrapper = to ? Link : 'span';
  return (
    <Wrapper
      className={classNames(
        'badge',
        className,
        types.map(type => `badge--${type}`),
      )}
      onClick={onClick}
      to={to}
    >
      <span className="badge__value">
        {children}
      </span>
    </Wrapper>
  );
};

Badge.defaultProps = {
  types: [],
  className: '',
};

interface BadgePropDefs {
  className?: string;
  types?: string[];
  onClick?();
  to?: LinkProps;
}

export default Badge;
