import * as R from 'ramda';
import { createSelector } from 'reselect';
import defineAbilitiesFor from 'utils/ability';

const getUserAbility = createSelector(
  R.prop('user'),
  defineAbilitiesFor,
);

export default getUserAbility;
