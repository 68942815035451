import createListDuck from 'utils/createListDuck';
import { getPath } from 'utils/duckTools';

export const { reducer, actions, selectors } = createListDuck(
  'ARTICLES_SINGLE_RELATED',
  getPath(__filename),
);

export default reducer;

