import React from 'react';
import { routeMetaDef } from 'state/routesMap';
import ComponentMap from 'components/general/ComponentMap/ComponentMap';
import SubscriptionWall from 'components/pages/SubscriptionWall';
import Can from 'containers/Can/Can';

/**
 * Wraps the primary page content in a Can, which checks if the current user
 * can use the page. If not, a pay-wall is displayed.
 */
const Paywall: React.SFC<PaywallPropDef> = ({ routeMeta, ...args }) => (
  <Can
    action={routeMeta.action}
    on={routeMeta.resource}
    feature={routeMeta.feature}
    fallbackRender={(
      <SubscriptionWall 
        message={routeMeta.paywallMessage}
        ctaEvent={routeMeta.paywallCTAEvent}
      />
    )}
  >
    <ComponentMap {...args} />
  </Can>
);

Paywall.defaultProps = {
  routeMeta: {},
};

interface PaywallPropDef {
  routeMeta?: routeMetaDef;
}

export default Paywall;

