const mappings = {
  escape: 27,
  down: 40,
  left: 37,
  right: 39,
};

/**
 * Converts a nice-name key to a keyCode.
 * @param {String} key - The name of the key pressed, in lowercase (e.g. 'down')
 * @returns {Number} The keyCode
 */
const keyMap = key => mappings[key];

export default keyMap;
