import * as R from 'ramda';
import { connect } from 'react-redux';
import { compose, mapProps } from 'recompose';
import track from 'react-tracking';
import getUserData from 'selectors/getUserData';

const withUserTracking = () => compose(
  connect(state => ({
    udTrack: R.pipe(
      getUserData,
    )(state),
  })),
  track(({ udTrack }) => ({ user: udTrack })),
  mapProps(R.omit(['udTrack'])),
);

export default withUserTracking;

