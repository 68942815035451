import * as R from 'ramda';
import StateType from 'utils/ts/StateType';

interface getPayload {
  (state: StateType): StateType;
}

/**
 * Gets the route/page action payload.
 */
const getPayload: getPayload = R.pathOr({}, ['location', 'payload']);

export default getPayload;
