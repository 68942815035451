import React, { Fragment } from 'react';
import * as R from 'ramda';
import Downshift from 'downshift';
import classNames from 'classnames';
import { compose, onlyUpdateForKeys } from 'recompose';
import Icon from 'components/general/Icon/Icon';
import withRenderPropsFactory from 'components/hoc/withRenderPropsFactory';
import withAjaxResults from 'components/hoc/withAjaxResults/withAjaxResults';
import Skeleton from 'components/general/Skeleton/Skeleton';
import Dimmer from 'components/general/Dimmer/Dimmer';

const ResultsSkeleton = onlyUpdateForKeys([])(() => (
  <Fragment>
    {R.times(
      () => (
        <div className="search__result">
          <Skeleton width={[300, 540]} types={['short']} />
        </div>
      ),
      4,
    )}
  </Fragment>
));

class SearchBox extends React.Component<PropDef> {
  // Ref to the input DOM element
  private inputElem = null;
  onInputValueChange = (query) => {
    const { onInput } = this.props;
    if (onInput) onInput(query);
  }
  onSelect = (val) => {
    const { onSelect } = this.props;
    // Run callback
    if (onSelect) onSelect(val);
    // Blur search input after submission, hides mobile keyboard
    this.inputElem.blur();
  }
  setInputRefs = (ref) => {
    const { inputRef } = this.props;
    // Update refs in parent if ref fn is passed down
    if (inputRef) inputRef(ref);
    // Also add ref locally
    this.inputElem = ref;
  }
  render() {
    const {
      isLoading,
      placeholder,
      itemToString,
      items,
      inputClass,
      dimmer,
      onBlur,
    } = this.props;

    return (
      <Downshift
        onInputValueChange={this.onInputValueChange}
        onSelect={this.onSelect}
        itemToString={itemToString}
      >
        {({
          getInputProps,
          getItemProps,
          isOpen,
          inputValue,
          clearSelection,
          highlightedIndex,
        }) => {
          const inputProps = R.omit(
            ['inputClassName'],
            getInputProps({
              onBlur,
              id: 'search-input',
              className: classNames('input-icon__input', inputClass),
              type: 'search',
            }),
          );

          const searchClasses = classNames('search', {
            'search--is-open': isOpen,
          });

          const onClear = () => {
            clearSelection();
            if (this.props.onClear) this.props.onClear();
          };

          // Explicit about value, since undefined val breaks controlled input.
          const {
            value = '',
            ...generalInputProps
          } = inputProps;

          return (
            <div className={searchClasses}>
              {dimmer && (
                <Dimmer
                  onClose={onClear}
                  isActive={isOpen}
                  closeOnClick
                  fixed
                />
              )}
              <div className="search__box input-icon input-icon--has-clear input-icon--left">
                <Icon className="input-icon__icon" name="search" />
                <input
                  {...generalInputProps}
                  value={value}
                  placeholder={placeholder}
                  ref={this.setInputRefs}
                />
                {inputValue && (
                  <button className="input-icon__clear" onClick={onClear}>
                    <Icon name="close" />
                  </button>
                )}
                {isOpen && (
                  <div className="search__dropdown">
                    {isLoading && (
                      <div className="search__results">
                        <ResultsSkeleton />
                      </div>
                    )}
                    {(!isLoading && (!items || items.length === 0)) && (
                      <div className="search__result">
                        <span className="search__message">
                          No results found
                        </span>
                      </div>
                    )}
                    {(items && items.length > 0 && !isLoading) && (
                      <ul className="search__results">
                        {this.props.items.map((item, index) => (
                          <li
                            {...getItemProps({
                              item,
                              index,
                              isActive: highlightedIndex === index,
                            })}
                            className={classNames('search__result', {
                              'seach__result--is-selected': highlightedIndex === index,
                            })}
                          >
                            {this.props.renderResult(item)}
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                )}
              </div>
            </div>
          );
        }}
      </Downshift>
    );
  }
}

SearchBox.defaultProps = {
  items: [],
  inputClass: '',
  dimmer: false,
};

interface PropDef {
  /** The results */
  items: (object|string)[];
  isLoading: boolean;
  onInput(query: string);
  itemToString(item: object):string;
  renderResult(object);
  dimmer?: boolean;
  inputClass?: string;
  placeholder?: string;
  onSelect?(val: object);
  onClear?();
  inputRef?(any);
}

export default compose(
  withRenderPropsFactory({ props: ['renderResult'] }),
  withAjaxResults({
    propMapping: {
      results: 'items',
      fetchResults: 'onInput',
    },
  }),
)(SearchBox);

