import React from 'react';
import * as R from 'ramda';
import Flash, { FlashPropDef } from 'components/general/Flash/Flash';

class FlashList extends React.Component<PropDef, StateDef> {
  private timers: object = {};

  constructor(props) {
    super(props);
    this.state = {
      hidden: [],
    };
  }
  componentDidMount() {
    this.updateTimers();
  }
  componentDidUpdate() {
    this.updateTimers();
  }
  updateTimers() {
    if (this.props.autoDismiss) {
      this.props.flashes.forEach((flash) => {
        if (!this.timers[flash.id]) {
          this.timers[flash.id] = setTimeout(() => {
            this.setState(state => ({
              hidden: R.append(flash.id, state.hidden),
            }));
          }, 3000);
        }
      });
    }
  }
  render() {
    const visible = this.props.flashes
      .filter(flash =>
        R.indexOf(flash.id, this.state.hidden) === -1);

    if (!visible.length) return null;

    return (
      <ul className="u-delist">
        {visible.map(flash => (
          <li key={flash.id}>
            <Flash {...flash} />
          </li>
        ))}
      </ul>
    );
  }
}

interface StateDef {
  hidden: string[];
}

interface FlashListItemDef extends FlashPropDef {
  id: string;
}

interface PropDef {
  autoDismiss?: boolean;
  flashes: FlashListItemDef[];
}

export default FlashList;

