/**
 * login
 */
import { redirect } from 'redux-first-router';
import getUserStatus from 'selectors/getUserStatus';

const route = async (dispatch, getState) => {
  const state = getState();
  const status = getUserStatus(state);

  // Redirect the user home if they're already logged in
  if (status.authenticated) {
    dispatch(redirect({ type: 'DASHBOARD' }));
  }
};

export default route;
