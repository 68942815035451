/**
 * http://varun.ca/icon-component/
 */
import React from 'react';
import requireAllSVG from 'utils/requireAllSVG';

requireAllSVG();

const InlineSVG: React.SFC<InlineSVGProps> = ({
  name,
  width,
  height,
  className,
  decorative,
}) => (
  <svg
    width={width}
    height={height}
    className={className}
    aria-hidden={decorative}
  >
    <use xlinkHref={`#${name}`} />
  </svg>
);

interface InlineSVGProps {
  name: string;
  width?: number|'auto';
  height?: number|'auto';
  className?: string;
  decorative?: boolean;
}

InlineSVG.defaultProps = {
  width: 0,
  height: 0,
};

export default InlineSVG;

