/**
 * Authentication
 */

const auth = (state = {}, action) => {
  switch (action.type) {
    case 'LOGIN_SUCCESS':
      return {
        idToken: action.idToken,
        accessToken: action.accessToken,
      };
    case 'LOGOUT':
      return {};
    default:
      return state;
  }
};

export default auth;
