/**
 * pageMap
 * A mapping of Redux route action type to Page component.
 */
import React from 'react';
import Loadable from 'react-loadable';
import universal from 'react-universal-component';
import Island from 'components/siteLayouts/Island';
import Dimmer from 'components/general/Dimmer/Dimmer';
import Spinner from 'components/general/Spinner/Spinner';

const loading = () => (
  <Dimmer isActive>
    <Spinner />
  </Dimmer>
);

// Page containers, code-split.
// Can't use dynamic import expressions or risk increasing bundle size.
const Forbidden = Loadable({
  loading,
  loader: () => import('components/pages/Forbidden'),
});
const Loading = Loadable({
  loading,
  loader: () => import('screens/shared/components/Loading'),
});
const Dashboard = Loadable({
  loading,
  loader: () => import('screens/dashboard/index'),
});
const countries = Loadable({
  loading,
  loader: () => import('screens/countries/index'),
});
const countriesGeography = Loadable({
  loading,
  loader: () => import('screens/countries/screens/single/index')
});
const countriesGeographyReport = Loadable({
  loading,
  loader: () => import('screens/countries/screens/report/index')
});
const countriesComparison = Loadable({
  loading,
  loader: () => import('screens/countries/screens/compare/index'),
});
const AlertsIndex =  Loadable({
  loading,
  loader: () => import('screens/alerts/index'),
});
const AlertsMap = Loadable({
  loading,
  loader: () => import('screens/map/index'),
});
const AlertsSingle = Loadable({
  loading,
  loader: () => import('screens/alerts/screens/single/index'),
});
const ArticlesIndex = Loadable({
  loading,
  loader: () => import('screens/articles/index'),
});
const ArticlesSingle = Loadable({
  loading,
  loader: () => import('screens/articles/screens/single/index'),
});
const InfoPage = Loadable({
  loading,
  loader: () => import('screens/info/index'),
});
const Login = Loadable({
  loading,
  loader: () => import('components/pages/Login'),
});
const ResetPassword = Loadable({
  loading,
  loader: () => import('components/pages/ResetPassword'),
});
const ForgotPassword = Loadable({
  loading,
  loader: () => import('components/pages/ForgotPassword'),
});
const Account = Loadable({
  loading,
  loader: () => import('screens/account/screens/index'),
});
const AcceptInvite = Loadable({
  loading,
  loader: () => import('screens/acceptInvite/index'),
});
const RegisterBusinessInterest = Loadable({
  loading,
  loader: () => import('screens/registerBusinessInterest/index'),
});

const components = {
  HOME: Login,
  FORBIDDEN: Forbidden,
  DASHBOARD: Dashboard,
  COUNTRIES: countries,
  COUNTRIES_GEOGRAPHY: countriesGeography,
  COUNTRIES_REPORT: countriesGeographyReport,
  COUNTRIES_COMPARISON: countriesComparison,
  ALERTS_INDEX: AlertsIndex,
  MAP: AlertsMap,
  ALERTS_SINGLE: AlertsSingle,
  ARTICLES_INDEX: ArticlesIndex,
  ARTICLES_SINGLE: ArticlesSingle,
  INFO: InfoPage,
  LOGIN: Login,
  LOGOUT: Login,
  RESET_PASSWORD: ResetPassword,
  FORGOT_PASSWORD: ForgotPassword,
  ACCEPT_INVITE: AcceptInvite,
  REGISTER_BUSINESS_INTEREST: RegisterBusinessInterest,
  /**
   * screens.account.account
   * All 'screens.account.account' based routes go through one page
   * template, to avoid the sidebar being re-fetching/re-mounted.
   */
  ORGANISATION: Account,
  ORGANISATION_USERS: Account,
  ORGANISATION_USERS_SINGLE: Account,
  ORGANISATION_INVITATIONS: Account,
  ORGANISATION_INVITATIONS_CREATE: Account,
  ORGANISATION_ASSETS: Account,
  ORGANISATION_CUSTOMERS: Account,
  ORGANISATION_CUSTOMERS_SINGLE: Account,
  ORGANISATION_CUSTOMERS_SINGLE_USERS: Account,
  ORGANISATION_CUSTOMERS_SINGLE_USERS_SINGLE: Account,
  ORGANISATION_CUSTOMERS_CREATE: Account,
  ORGANISATION_CUSTOMERS_SINGLE_INVITATIONS: Account,
  ORGANISATION_CUSTOMERS_SINGLE_INVITATIONS_CREATE: Account,
  ORGANISATION_CUSTOMERS_SINGLE_ASSETS: Account,
  ORGANISATION_CUSTOMERS_SUBSCRIPTION: Account,
  PREFERENCES: Account,
  PREFERENCES_INTERESTS: Account,
  PREFERENCES_EMAILS: Account,
  ADMIN_SUBSCRIPTIONS: Account,
  ADMIN_SUBSCRIPTIONS_CREATE: Account,
  ADMIN_SUBSCRIPTIONS_SINGLE: Account,
};

export default components;

