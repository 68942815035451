import React, { ReactElement } from 'react';
import classNames from 'classnames';
import Button from 'components/general/Button/Button';
import Icon from 'components/general/Icon/Icon';
import LinkProps from 'utils/ts/LinkProps';
import toSlug from 'utils/toSlug';

const SubHeader: React.SFC<PropDef> = ({
  title,
  id,
  summary,
  action,
  renderAction,
}) => (
  <header
    className={classNames('sub-header', {
      'sub-header--align-center': !summary,
    })}
  >
    <div className="sub-header__inner">
      <div className="sub-header__main">
        <h2
          className="sub-header__title"
        >
          {/* Add an on-page anchor to headings */}
          {id === true && (
            <a
              id={toSlug(title)}
              href={`#${toSlug(title)}`}
              className="sub-header__link"
            >
              <Icon name="link" />
            </a>
          )}
          <span className="sub-header__title-content">
            {title}
          </span>
        </h2>
        {summary && (
          <span className="sub-header__summary">
            {summary}
          </span>
        )}
      </div>
      {(renderAction || action) && (
        <div className="sub-header__actions">
          {renderAction && renderAction()}
          {(action && !renderAction) && (
            <Button
              to={action.to}
              event={action.event}
              types={['sm']}
              classes={['sub-header__action']}
            >
              {action.icon && (
                <Icon name={action.icon} types={['push-right']} />
              )}
              {action.title}
            </Button>
          )}
        </div>
      )}
    </div>
  </header>
);

interface PropDef {
  title: string;
  // If in-page anchors should be added
  id?: boolean;
  summary?: string|ReactElement<any>;
  action?: {
    title: string,
    icon: string,
    to: LinkProps,
    event?: string,
  };
  renderAction?();
}

export default SubHeader;

