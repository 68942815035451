import * as R from 'ramda';
import { createReducer } from 'redux-act';
import createNewRequestActions from 'utils/createNewRequestActions';
import createLoadingReducer from 'utils/createLoadingReducer';
import { globalize } from 'utils/duckTools';

/**
 * Creates actions/selectors/reducers for working with basic AJAX data (no
 * transformations, just grab it and store it).
 * @param {String} name Unique name on which the action types will be based.
 * Should follow the redux format of all caps, underscores, e.g. TEST_ACTION
 * @param {Array} slice The path to the slice of the state we're targeting,
 * e.g. ['screens', 'ui']
 * @param {Boolean} single If it's a single entry returned as the only
 * element in an array, thus needs to be 'extracted'.
 * @param {Boolean} cache If we should store it by key instead of swapping
 * it out when we get new data. Essentially builds a list of cached items by
 * ID, such as for details when on the Alerts Map screen.
 * @returns {Object} Generated reducer/selectors/actions.
 * @property {Object} reducer The reducer for the duck.
 * @property {Object} actions The duck actions.
 * @property {Object} selectors The duck selectors.
 */
const createGenericDataDuck = (name, slice, process = R.identity, cacheBy) => {
  const actions = createNewRequestActions(name);

  const reducer = {
    data: createReducer({
      [actions.types.success]: (state, payload) => (
        R.pipe(
          R.prop('data'),
          R.when(
            R.pipe(
              R.length,
              R.equals(1),
            ),
            R.head,
          ),
          R.ifElse(
            R.always(
              R.isNil(cacheBy),
            ),
            process,
            R.pipe(
              data => ({ [cacheBy(data)]: process(data) }),
              R.merge(state),
            ),
          ),
        )(payload)
      ),
    }, {}),
    loading: createLoadingReducer(name),
  };

  const selectors: { data(object), loading(object) } = globalize({
    data: ['data'],
    loading: ['loading'],
  }, slice);

  return {
    actions,
    reducer,
    selectors,
  };
};

export default createGenericDataDuck;

