import * as R from 'ramda';

/**
 * Used to get the 'path' to the passed in file param. Removes 'ducks'
 * since the state shape shouldn't have it.
 * @param {String} __filename Expects the exposed webpack __filename variable.
 * @return {Array} The current path in an array format, e.g. ['screens',
 * 'articles'], minus 'ducks' and the file extension.
 * @example
 * getPath(__filename);
 */
export const getPath = R.pipe(
  R.replace(/\.js|\.ts|\.duck.js|\.duck.ts|ducks\//g, ''),
  R.split('/'),
  R.drop(1),
);

/**
 * Concatenates the passed in array with the path passed in, creating a
 * 'global' selector out of a local one.
 * Takes an object containing array values, or a single array value.
 * @param {Array|Object} selectors The local path for the selection, e.g.
 * ['pagination']. This is relative to the reducer/duck.
 * @param {Array} path The current path to the file that we'll use to
 * generate the global path. Likely created by {@link getPath}.
 * @returns {Array|Object} The same path arrays, now pre-pended with their
 * global state path.
 * @example
 * globalize(['order'], getPath(__filename));
 *
 * globalize(['order'], ['screens', 'articles', 'list']);
 *
 * globalize({
 *   order: ['order'],
 *   items: ['items'],
 * }, ['screens', 'articles', 'list']);
 */
export const globalize = (selectors, path) => {
  const toGlobal = sel => (
    R.path(R.concat(path, sel))
  );

  if (!selectors || !path) {
    console.error(
      'globalize must be passed selectors and a path array',
      { selectors, path },
    );
    return null;
  }

  // Object containing selectors
  // e.g. { title: R.prop('order') }
  if (Array.isArray(selectors)) {
    // Single selector
    // e.g. globalize(R.prop('order'), slice)
    return toGlobal(selectors);
  } else if (typeof selectors === 'object') {
    return R.map(toGlobal, selectors);
  }

  console.error('globalize passed neither object nor array');

  return null;
};

