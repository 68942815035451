/**
 * ModalDialog
 */
import React from 'react';
import PropTypes from 'prop-types';
import Button from './../Button/Button';
import Icon from '../Icon/Icon';
import withOutsideClick from './../../hoc/withOutsideClick';

class ModalDialog extends React.Component {
  handleClickOutside() {
    this.props.onClose();
  }
  render() {
    const { onClose, children } = this.props;

    return (
      <div className="modal__dialog">
        <Button onClick={onClose} types={['icon']} classes={['modal__close-btn']} data-test="modal-close">
          <Icon name="close" types={['icon']} />
        </Button>
        {children}
      </div>
    );
  }
}

ModalDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

export default withOutsideClick(ModalDialog);
