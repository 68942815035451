/**
 * Adds pop-over/dropdown-style functionality to a component.
 * Exposes properties/functions (e.g. isOpen).
 */
import React from 'react';
import { compose } from 'recompose';
import wrapDisplayName from 'recompose/wrapDisplayName';
import keyMap from 'utils/keyMap';
import withOutsideClick from './withOutsideClick';
import withOnEscape from './withOnEscape';

const enhanceWithPopover = (Component, options) => {
  class EnhancedComponent extends React.PureComponent {
    constructor(props) {
      super(props);
      this.state = {
        isOpen: false,
      };

      this.toggle = this.toggle.bind(this);
      this.open = this.open.bind(this);
      this.close = this.close.bind(this);
      this.handleClickOutside = this.handleClickOutside.bind(this);
      this.onTriggerKeydown = this.onTriggerKeydown.bind(this);

      this.triggerProps = {
        onClick: this.toggle,
        onKeyDown: this.onTriggerKeydown,
      };
    }
    onTriggerKeydown(e) {
      if (keyMap('down') === e.keyCode) {
        this.open();
      }
    }
    open() {
      this.setState({ isOpen: true });
    }
    close() {
      this.setState({ isOpen: false });
    }
    toggle() {
      this.setState({ isOpen: !this.state.isOpen });
    }
    // Added/used by withEscape
    handleEscape() {
      this.close();
    }
    // Added/used by withOutsideClick
    handleClickOutside() {
      if (options && options.autoDismiss) this.close();
    }
    render() {
      return (
        <Component
          {...this.props}
          isOpen={this.state.isOpen}
          onClose={this.close}
          triggerProps={this.triggerProps}
        />
      );
    }
  }

  EnhancedComponent.displayName = wrapDisplayName(Component, 'withPopover');

  return EnhancedComponent;
};

// Adds popover's dependencies for additional
// handlers, e.g. handleClickOutside()
const withPopover = compose(
  withOnEscape,
  withOutsideClick,
  enhanceWithPopover,
);

export default withPopover;
