import React, { Fragment } from 'react';
import Link from 'components/general/Link/Link';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Icon from 'components/general/Icon/Icon';
import Button from 'components/general/Button/Button';
import Modal from 'components/general/Modal/Modal';
import LinkProps from 'utils/ts/LinkProps';
import SubHeader from 'components/general/SubHeader/SubHeader';

const ConfirmModal = ({ action, onClose, renderInner }) => {
  const confirm = action.confirm;

  const executeAction = () => {
    action.fn();
    onClose();
  };

  return (
    <Modal
      isOpen
      onClose={onClose}
    >
      {renderInner ? renderInner() : (
        <Fragment>
          <SubHeader title={confirm.title} summary={confirm.message} />
          <div className="u-space">
            <Button onClick={executeAction} types={['accent']}>Delete user</Button>
            <Button onClick={onClose}>Cancel</Button>
          </div>
        </Fragment>
      )}
    </Modal>
  );
};

ConfirmModal.propTypes = {
  confirm: {
    action: PropTypes.shape({
      confirm: PropTypes.func.isRequired,
    }),
    onClose: PropTypes.func.isRequired,
  },
  renderInner: PropTypes.func,
};

// The UI for the Dropdown
class DropdownMenu extends React.PureComponent<DropdownMenuProps, DropdownMenuState> {
  constructor(props) {
    super(props);

    this.state = {
      // Any action awaiting confirmation
      prompt: null,
    };
  }
  setPrompt = (action) => {
    this.setState({ prompt: action });
  }
  clearPrompt = () => {
    this.setState({ prompt: null });
  }
  renderPrompt() {
    /* The modal that displays when selecting an action */
    if (!this.state.prompt) return null;

    if (this.state.prompt.confirm) {
      return (
        <ConfirmModal
          action={this.state.prompt}
          onClose={this.clearPrompt}
        />
      );
    } else if (this.state.prompt.prompt) {
      return (
        <Modal
          isOpen
          onClose={this.clearPrompt}
        >
          {this.state.prompt.prompt(this.clearPrompt)}
        </Modal>
      );

    }

    return null;
  }
  render() {
    const {
      items,
      isOpen,
      onSelect,
      className,
    } = this.props;

    const classes = classNames('dropdown-menu', 'dropdown', className, {
      'dropdown--is-open': isOpen,
    });

    return (
      <Fragment>
        {this.renderPrompt()}
        <div className={classes}>
          {items.map(item => (
            <div className="dropdown-menu__section" key={item.title}>
              <h4 className="dropdown-menu__heading">{item.title}</h4>
              <ul className="dropdown-menu__list">
                {item.actions.map((action) => {
                  const inner = (
                    <Fragment>
                      {action.icon && (
                        <Icon name={action.icon} types={['push-right']} />
                      )}
                      {action.title}
                    </Fragment>
                  );

                  let item;

                  if (action.to) {
                    {/* Link actions */}
                    item = (
                      <Link
                        to={action.to}
                        className="dropdown-menu__item"
                        onClick={onSelect}
                      >
                        {inner}
                      </Link>
                    );
                  } else {
                    {/* Button actions */}
                    {/* TODO: Cleanup */}
                    item = (
                      <Button
                        onClick={() => {
                          onSelect();

                          if (action.confirm || action.prompt) {
                            this.setPrompt(action);
                          } else if (action.fn) {
                            action.fn();
                          }
                        }}
                        classes={['dropdown-menu__item']}
                        types={['plain']}
                      >
                        {inner}
                      </Button>
                    );
                  }

                  return (
                    <li key={action.title}>
                      {item}
                    </li>
                  );
                })}
              </ul>
            </div>
          ))}
        </div>
      </Fragment>
    );
  }
}

export interface DropdownMenuProps {
  items: MenuAction[];
  className?: string;
  isOpen?: boolean;
  // Run when an item is clicked
  onSelect?();
}

interface DropdownMenuState {
  prompt;
}

export interface MenuAction {
  title: string;
  icon?: string;
  // If it's a link
  to?: LinkProps;
  // A function run on-select/click, if it's not a link
  fn?();
  // If it's a confirmation modal/prompt
  confirm?(data: object);
  // If it's a prompt (more flexible than a confirm)
  prompt?(clearPrompt: () => {});
}

export default DropdownMenu;
