import api from 'server/api';
import reduxFetch from 'utils/reduxFetch';
import fetchData from 'utils/fetchData';
import getCustomerID from 'selectors/getCustomerID';
import getSiteQuery from 'selectors/getSiteQuery';
import customerThunk from 'screens/account/screens/organisation/screens/customers/screens/customer/screens/invitations/thunk';
import { actions as subscriptionsActions } from './ducks/subscriptions.duck';
import { actions as customerSubscriptionActions } from './ducks/customerSubscription.duck';

const route = async (dispatch, getState) => {
  const state = getState();
  const id = getCustomerID(state);
  const values = getSiteQuery(state);

  return Promise.all([
    dispatch(customerThunk),
    dispatch(reduxFetch({
      fetch: api('subscription', 'get'),
      actions: subscriptionsActions,
    })),
    dispatch(reduxFetch({
      fetch: fetchData('GET', `customer/${id}/subscription`),
      actions: customerSubscriptionActions,
      perPage: 10,
      filters: { ...values },
    })),
  ]);
};

export default route;
