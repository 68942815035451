import createTableDuck from 'utils/createTableDuck';
import { getPath } from 'utils/duckTools';

export const { selectors, actions, reducer } = createTableDuck(
  'MANAGE_CUSTOMERS_CUSTOMER_USERS',
  getPath(__filename),
);

export default reducer;

