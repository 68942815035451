import classNames from 'classnames';
// import track from 'react-tracking';
import Button from 'components/general/Button/Button';
import Icon from 'components/general/Icon/Icon';
import React from 'react';
import LinkProps from 'utils/ts/LinkProps';
import RenderPropType from 'utils/ts/RenderPropType';

const icons = {
  neutral: 'info',
  success: 'check',
  danger: 'close',
  warning: 'info',
  accent: 'info',
};

const Flash: React.SFC<FlashPropDef> = (props) => {
  const {
    type,
    icon,
    message,
    actions,
  } = props;

  return (
    <div className={classNames('flash', `flash--${type}`)}>
      <Icon name={icon || icons[type]} className="flash__icon" />
      <div className="flash__content">
        {
          typeof message === 'function'
            ? message(props)
            : (
              <div className="rich-text">
                <p>{message}</p>
              </div>
            )
        }
        {actions && (
          <div className="flash__actions">
            {actions.map(action => (
              <Button
                key={action.to}
                event={action.event}
                to={action.to}
                onClick={action.onClick}
                types={['sm', ...action.types]}
              >
                {action.title}
              </Button>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

Flash.defaultProps = {
  type: 'neutral',
};

export interface FlashPropDef {
  message: string | RenderPropType;
  icon?: string;
  type?: string;
  actions?: {
    title: string;
    event?: string;
    types?: string[];
    to: LinkProps | string;
    onClick?();
  }[];
}

export default Flash;

