import * as R from 'ramda';
import { createAction, createReducer } from 'redux-act';
import createListDuck from 'utils/createListDuck';
import { globalize } from 'utils/duckTools';

/**
 * Creates actions/selectors/reducers for working with async tabular-data.
 * @param {String} name Unique name on which the action types will be based.
 * Should follow the redux format of all caps, underscores, e.g. TEST_ACTION
 * @param {Array} slice The path to the slice of the state we're targeting,
 * e.g. ['screens', 'ui']
 * @returns {Object} Generated reducer/selectors/actions.
 * @property {Object} reducer The reducer for the duck.
 * @property {Object} actions The duck actions.
 * @property {Object} selectors The duck selectors.
 */
const createTableDuck = (name, slice, createIndex) => {
  const addPending = createAction(`${name}_ADD_PENDING`);
  const removePending = createAction(`${name}_REMOVE_PENDING`);

  const actions = {
    addPending,
    removePending,
  };

  const selectors = globalize({
    pending: ['pending'],
  }, slice);

  const pending = createReducer({
    [actions.addPending]: (state, payload) => (
      R.append(payload, state)
    ),
    [actions.removePending]: (state, payload) => (
      R.reject(R.equals(payload), state)
    ),
  }, []);

  return R.mergeDeepRight(createListDuck(name, slice, createIndex), {
    actions,
    selectors,
    reducer: {
      pending,
    },
  });
};

export default createTableDuck;

