import * as R from 'ramda';

/**
 * Base class for an analytics provider, e.g. GA.
 */
class MetricVendor {
  name: string;
  env: string[];
  listenFor: string[];
  constructor(args) {
    const { name, listenFor = [], env = [] } = args;
    this.name = name;
    this.listenFor = listenFor;
    this.env = env;
  }
  isInterested(event) {
    const env = process.env.NODE_ENV;
    return R.contains(env)(this.env) && R.contains(event, this.listenFor);
  }
  notify(data) {
    const { event } = data;
    if (this.isInterested(event)) {
      this.track(data);
    }
  }
  track(data) {
    console.error('Track not implemented', data);
  }
}

export default MetricVendor;
