import Badge from 'components/general/Badge/Badge';
import CalendarDate from 'components/general/CalendarDate/CalendarDate';
import React, { Fragment } from 'react';
import classNames from 'classnames';
import Link from 'components/general/Link/Link';
import Profile from 'components/general/Profile/Profile';
import SiteImage from 'containers/SiteImage';
import Icon from 'components/general/Icon/Icon';
import Button from 'components/general/Button/Button';
import DateTime, { DateTimeProps } from 'components/general/DateTime/DateTime';
import LinkProps from 'utils/ts/LinkProps';

interface ProfileProps {
  name: string;
  photo?: string;
}

class Media extends React.Component<MediaProps, MediaState> {
  constructor(props) {
    super(props);
    this.state = {
      isCollapsed: true,
    };
  }
  toggleCollapsed = () => {
    this.setState(state => ({ isCollapsed: !state.isCollapsed }));
  }
  render () {
    const {
      title,
      category,
      image,
      author,
      href,
      date,
      actions,
      summary,
      horizontal,
      small,
      event,
      tags,
      'data-test': dataTest,
    } = this.props;

    const className = classNames('media', {
      'media--small': small,
      'media--no-img': !image && !event,
      'media--event': event,
    });

    const crops = horizontal
      ? [
        { w: 200, h: 200 },
        { w: 400, h: 400 },
        { w: 800, h: 800 },
      ]
      : [
        { w: 200, h: 150 },
        { w: 400, h: 300 },
        { w: 800, h: 600 },
      ];

    return (
      <article
        itemScope
        itemType="http://schema.org/NewsArticle"
        className={className}
        data-test={dataTest}
      >
        {(image && !event) && (
          <Link to={href} className="media__image">
            <SiteImage
              src={image.src}
              crops={crops}
              alt={image.alt}
              className="media__image-placeholder"
            />
          </Link>
        )}
        {(date && event) && (
          <div className="media__calendar-date">
            <CalendarDate
              datetime={date.datetime}
              iso8601={date.iso8601}
            />
          </div>
        )}
        <div className="media__content">
          {category &&
            <span className="media__category" data-test={`${dataTest}__category`}>
              {category.href ? (
                <Link to={category.href}>{category.title}</Link>
              ) : <span>{ category.title }</span> }
            </span>
          }
          <h3 itemProp="headline" className="media__title" data-test={`${dataTest}__title`}>
            {href
              ? (
                <Link to={href} data-test={`${dataTest}__link`}>
                  {title}
                </Link>
              )
              : title
            }
          </h3>
          {summary && (
            <Fragment>
              {summary.length > 350 ? (
                <p itemProp="description" className="media__summary">
                  {this.state.isCollapsed ? (
                    <Fragment>
                      {`${summary.replace(/^(.{350}[^\s]*).*/, '$1')}`}&hellip;&nbsp;
                      <Button
                        types={['sm', 'link']}
                        onClick={this.toggleCollapsed}
                      >
                        Read more
                      </Button>
                    </Fragment>
                  ) : (
                    <Fragment>
                      {summary} &nbsp;
                      <Button
                        types={['sm', 'link']}
                        onClick={this.toggleCollapsed}
                      >
                        Read less
                      </Button>
                    </Fragment>
                  )}
                </p>
              ) : (
                <p itemProp="description" className="media__summary">
                  {summary}
                </p>
              )}
            </Fragment>
          )}
          {(author || date || actions) && (
            <footer className="media__footer">
              <div className="media__meta">
                {(author && author.name) &&
                  <div
                    itemProp="author"
                    itemScope
                    itemType="https://schema.org/Person"
                    className="media__author"
                  >
                    <Profile
                      name={author.name}
                      photo={author.photo}
                      to={author.to}
                    />
                  </div>
                }
                {(date && !event) && (
                  <DateTime
                    datetime={date.datetime}
                    human={date.human}
                    iso8601={date.iso8601}
                    className="media__date"
                  />
                )}
                {tags && (
                  <div className="media__tags">
                    <ul className="media__tags-list">
                      {tags.map(tag => (
                        <li className="media__tags-item" key={tag.label}>
                          <Badge
                            to={tag.to}
                            types={tag.type === 'primary' ? ['accent'] : []}
                          >
                            <Icon
                              name={tag.type === 'primary' ? 'alert-circle' : 'tag'}
                              types={['push-right']}
                            />
                            {tag.label}
                          </Badge>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
              {actions &&
              <ul className="media__actions">
                {actions.map(action => (
                  <li className="media__action" key={action.title}>
                    {/*<Tooltip title={action.title}>*/}
                      <Button
                        types={['icon']}
                        to={action.to}
                        onClick={action.onClick}
                      >
                        <Icon name={action.icon} />
                      </Button>
                    {/*</Tooltip>*/}
                  </li>
                ))}
              </ul>
              }

            </footer>
          )}
        </div>
      </article>
    );
  }
}

export interface MediaProps {
  title: string;
  category?: {
    title: string;
    href?: LinkProps,
  };
  tags?: {
    label: string;
    type: 'primary' | 'secondary';
    to: LinkProps;
  }[];
  image?: {
    src: string;
    alt: string;
  };
  author?: ProfileProps;
  href?: LinkProps;
  date?: DateTimeProps;
  actions?: {
    title: string;
    icon?: string;
    to?: LinkProps;
    onClick?();
  }[];
  summary?: string;
  horizontal?: boolean;
  // Displays calendar-style date
  event?: boolean;
  small?: boolean;
  'data-test'?: string;
}

interface MediaState {
  isCollapsed: boolean;
}

export default Media;

