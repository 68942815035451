import * as R from 'ramda';
import { getPath } from 'utils/duckTools';
import createTableDuck from 'utils/createTableDuck';
import { createSelector } from 'reselect';

export const { reducer, actions, selectors } = createTableDuck(
  'MANAGE_CUSTOMERS_CUSTOMER_SUBSCRIPTION',
  getPath(__filename),
  ({ id, created }) => `${id}_${created}`,
);

export const getCurrentSub = createSelector(
  selectors.inOrder,
  R.find(R.propEq('current', true)),
);

export default reducer;

