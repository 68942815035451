import React from 'react';
import { connect } from 'react-redux';
import * as R from 'ramda';
import SEOMeta from 'containers/SEOMeta';
import Flash from 'components/general/Flash/Flash';
import Island from 'components/siteLayouts/Island';
import getMarketingUrl from 'selectors/getMarketingUrl';

// @todo: Flesh this out, form, copy etc. TBC.
const SubscriptionWall: React.SFC<PropDef> = ({ message, ctaEvent , marketingUrl }) => (
  <Island>
    <SEOMeta title="Subscribe" />
    <Flash
      icon="info"
      type="accent"
      message={message}
      actions={[{
        title: 'Find out more',
        types: ['accent'],
        to: marketingUrl,
        event: ctaEvent,
      }]}
    />
  </Island>
);

SubscriptionWall.defaultProps = {
  message: 'This feature is not available for your current subscription level.',
};

interface PropDef {
  message?: string;
  ctaEvent?: string;
  marketingUrl: string;
}

export default connect(R.applySpec({
  marketingUrl: getMarketingUrl,
}))(SubscriptionWall);
