import * as R from 'ramda';
import { connect } from 'react-redux';
import {
  branch,
  withStateHandlers,
  renameProps,
  mapProps,
  compose,
} from 'recompose';

/**
 * A wrapper for fetching and passing down AJAX results to component (e.g.
 * Combobox).
 * @param [options]
 * @param [options.propMapping] Mapping of props back to wrapped
 * Component, e.g. 'results' becomes 'items' for use with Combobox.
 * @param [options.propMapping.results] The prop for data returned from
 * the AJAX call.
 * @param [options.propMapping.fetchResults] The prop function for
 * making an AJAX request.
 * @returns HOC that takes a component and wraps it.
 */
const withAjaxResults = ({
  propMapping = {
    results: 'items',
    fetchResults: 'onInput',
  },
}: options = {}) => branch(
  R.propIs(Function, 'fetchFn'),
  compose(
    withStateHandlers(
      {
        isLoading: false,
        results: [],
      },
      {
        setIsLoading: () => value => ({
          results: [],
          isLoading: value,
        }),
        setResults: () => data => ({
          results: data,
          isLoading: false,
        }),
      },
    ),
    connect(
      null,
      (dispatch, props) => ({
        fetchResults: async (query, ...args) => {
          if (props.fetchFn) {
            props.setIsLoading(true);

            const resp = await dispatch(
              props.fetchFn(query, ...args),
            );

            /**
             * Is it an axios request cancellation error? If so, don't bother
             * unsetting state, since this will stop showing the loader before
             * the replacement request has finished.
             */
            const isCancel = R.pipe(
              R.path(['data', 'errors', 0, 'type']),
              R.equals('cancel'),
            )(resp);

            if (resp.ok && !isCancel) {
              props.setResults(R.prop('data', resp) || []);
            } 
          }
        },
      }),
    ),
    branch(
      R.always(R.complement(R.isNil)(propMapping)),
      renameProps(propMapping),
    ),
    mapProps(
      R.omit([
        'setIsLoading',
        'setResults',
        'fetchFn',
      ]),
    ),
  ),
);

interface options {
  propMapping?: {
    results?: string,
    fetchResults?: string,
  };
}

export default withAjaxResults;

