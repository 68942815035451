import api from 'server/api';
import reduxFetch from 'utils/reduxFetch';
import { actions } from './ducks/regions.duck';

const route = async dispatch => (
  dispatch(
    reduxFetch({
      actions,
      fetch: api('geography', 'get'),
      filters: {
        type: 'globalRegion',
        fields: 'slug,id,title,type',
      },
    }),
  )
);

export default route;
