/**
 * Creates a padding-bottom placeholder wrapper around an element.
 */
import React from 'react';
import * as R from 'ramda';
import classNames from 'classnames';

type getPaddingBottom = 
  (dimensions: { width: number|string, height: number|string }) => string;

const getPaddingBottom: getPaddingBottom = R.pipe(
  R.map(x => parseInt(x, 10)),
  ({ width, height }) => (height / width) * 100,
  val => Math.round(val * 100) / 100,
  val => `${val}%`,
);

const Placeholder: React.SFC<PlaceholderProps> = ({
  width,
  height,
  children,
  background,
  fixedHeight,
  className,
}) => {
  const classes = classNames('placeholder', className, {
    'placeholder--background': background,
  });

  const inlineStyle = fixedHeight ? {
    height,
  } : {
    paddingBottom: getPaddingBottom({ width, height }),
  };

  return (
    <div className={classes} style={inlineStyle}>
      {children && children}
    </div>
  );
};

Placeholder.defaultProps = {
  className: '',
};

interface PlaceholderProps {
  width?: number;
  height?: number;
  className?: string;
  background?: boolean;
  fixedHeight?: boolean;
}

export default Placeholder;
