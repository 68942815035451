import * as R from 'ramda';
import { createSelector } from 'reselect';
import { selectors } from 'ducks/settings';
import getUserAbility from 'selectors/getUserAbility';

const getRiskTypes = createSelector(
  R.pipe(
    selectors.data,
    R.prop('riskTypes'),
  ),
  getUserAbility,
  (riskTypes, ability) =>
    R.reject(R.both(
      // Remove riskTypes which are not appropriate for subscription
      R.propEq('proSubscriptionOnly', true),
      R.always(ability.cannot('read', 'GRM')),
    ))(riskTypes),
);

export default getRiskTypes;
