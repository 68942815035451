import * as R from 'ramda';

/**
 * Maps query parameters to API parameters.
 * Isolated so it can be re-used accross view filter and export.
 */
const alertQueryToAPI = R.applySpec({
  riskTypes: R.prop('riskTypes'),
  contentTags: R.prop('contentTags'),
  start: R.path(['dateRange', 'start']),
  'geography.slug': R.tryCatch(
    R.pipe(
      R.prop('geographies'),
      R.map(R.props(['value'])),
      R.join(','),
    ),
    R.always(null),
  ),
  end: R.path(['dateRange', 'end']),
  search: R.prop('search'),
  sort: {
    by: R.prop('sort'),
    order: R.prop('order'),
  },
});

export default alertQueryToAPI;
