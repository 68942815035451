/**
 * Generates a random whole number between two points.
 * @param {Number} from The lowest number.
 * @param {Number} to The highest number.
 * @returns {Number} A number in-between from and to.
 */
const random = (from: number, to: number): number =>
  Math.floor((Math.random() * (to - (from + 1))) + from);

/**
 * Generates a random width between two points. Useful for skeleton screens.
 * This will break SSR, since the values will be different between
 * server/client. However it can be used on client-only
 * components/views, such as skeleton screens which should never be
 * server-rendered.
 * @param from The minimum width.
 * @param to The maximum width.
 * @param unit The unit that will be appended, e.g. 'px' or '%'
 * @returns A styles object containing a width prop.
 * @example
 * <div style={genWidth(100, 200, 'px')} />
 */
const genWidth = (from: number, to: number, unit: string = 'px'): widthStyle  => (
  { width: `${random(from, to)}${unit}` }
);

interface widthStyle {
  width: string;
}

export default genWidth;

