import * as R from 'ramda';
import fetchData from 'utils/fetchData';
import { FORM_ERROR } from 'final-form';
import getAccessToken from 'selectors/getAccessToken';

/**
 * A Redux thunk form factory. Eventually produces a thunk, e.g:
 *
 * `dispatch(postForm('/users/create')({ user: 'Jay' }());`
 *
 * This is done to provide non-explicit access to the Redux store.
 * @param {String} path - The URL path in in a string form, e.g. `user/signup`
 * @returns {function(data: Object): Object} JSON form data to post.
 */
const postForm = (path, id = null, method = 'post', options = {}) => data => (
  async (dispatch, getState) => {
    const state = getState();
    const auth = getAccessToken(state);

    const resp = await fetchData(method.toUpperCase(), path, {
      id,
      auth,
      body: data,
    });

    const errors = R.path(['data', 'errors'], resp);

    if (errors) {
      return {
        errors: Object.assign(
          {},
          ...errors
            .map((error) => {
              if (error.field) {
                return { [error.field]: error.message };
              }
              return { [FORM_ERROR]: error.message };
            }),
        ),
      };
    }

    if (options.onSuccess) {
      options.onSuccess(resp);
    }

    return { data: R.prop('data', resp) };
  }
);

export default postForm;
