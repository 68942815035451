/**
 * flashes
 * Global 'system' flash-data messages.
 */
import * as R from 'ramda';
import { createSelector } from 'reselect';
import { createAction, createReducer } from 'redux-act';
import Cookie from 'js-cookie';
import { getNextID } from '../../utils/elementIDCreator';

/**
 * Actions
 */
export const addFlashes = createAction('UPDATE_FLASHES');
export const clearFlashes = createAction(
  'CLEAR_FLASHES',
  () => Cookie.remove('flashes'),
);

/**
 * Selectors
 */
export const getStaticFlashes = createSelector(
  R.prop('flashes'),
  R.filter(R.propEq('position', 'static')),
);

export const getStickyFlashes = createSelector(
  R.prop('flashes'),
  R.filter(R.propEq('position', 'sticky')),
);

/**
 * Reducer
 */
const flashes = createReducer({
  [addFlashes]: (state, payload) => (
    payload
      ? R.map(R.set(R.lensProp('id'), getNextID()))(payload)
      : state
  ),
  [clearFlashes]: () => [],
}, {});

/**
 * Full reducer
 */
export default flashes;
