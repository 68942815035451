import { AbilityBuilder } from 'casl';
import * as R from 'ramda';
import { routeMetaDef } from 'state/routesMap';
import FlagType from 'utils/ts/FlagType';

/**
 * Casl 'ability' instance factory. Most permissions are based on
 * 'resources', i.e. discrete entities (Alerts/Articles etc), but 'feature'
 * is also used for less clearly defined functionality (e.g. display type of
 * an entity).
 * Preferences etc. should likely be 'resource' based, i.e. not a separate
 * thing, but a 'User' with a condition to edit (user.id === currentID etc).
 * However this adds a lot of unnecessary overhead when the requirements are
 * very simple - most routes are off limits and will 401 (and therefore
 * redirect) automatically.
 * @link https://stalniy.github.io/casl
 */
const defineAbilitiesFor = user => (
  AbilityBuilder.define({ subjectName: type => type }, (can, cannot) => {
    const flags: FlagType[] = R.path(['data', 'flags'], user);
    const authenticated = R.path(['status', 'authenticated'], user);

    can('read', 'Info');
    can('read', 'Article');

    if (authenticated) {
      can('read', 'Alert');
      can('read', 'Dashboard');
      can(['read', 'manage', 'use'], 'Preferences');
    } else {
      can('use', 'login');
    }

    if (!flags) return;

    if (!R.contains('HAS_DISMISSED_ONBOARDING', flags)) {
      can('use', 'onboarding');
    }

    if (R.contains('ACTIVE_SUBSCRIPTION', flags)) {
      can('use', 'digestEmails');
    }

    if (R.either(R.contains('PAID_DATA'), R.contains('SRM_ADMIN'))(flags)) {
      can('read', 'Geography');
      can('update', 'Dashboard');
      can('use', 'map');
      can('use', 'fullDigestEmails');
      can('use', 'paidData');
    }

    if (R.contains('RISK_GRM_DATA', flags)) {
      can('read', 'GRM');
    }

    if (R.contains('CUSTOMER_ADMIN', flags)) {
      can(['read', 'manage'], 'Organisation');
      cannot('update', 'Organisation', 'profile');
    }

    if (R.contains('RESELLER', flags)) {
      can(['read', 'manage'], 'Customer');
      cannot('update', 'Customer', 'profile');
    }

    if (R.contains('SRM_ADMIN', flags)) {
      // If you allow all on features you can't currently 'undo' things
      // like login, so don't include 'use'
      can(['read', 'manage'], 'all');
    }
  })
);

/**
 * Checks two things:
 * - Can the user access the route's primary entity?
 * - Are there any feature restrictions which prevent access?
 * The feature restriction is important for things such as the Alerts Map,
 * where the entity is accessible but not in one specific display-form.
 * @param meta - The route meta data, relating to feature/entity.
 * @param ability - A CASL ability instance, created by defineAbilitiesFor.
 */
export const isAllowed = (meta: routeMetaDef, ability, allowPaywall = true) => (
  ((R.has('resource', meta) && !R.propEq('paywall', allowPaywall, meta))
    ? ability.can(meta.action || 'read', meta.resource)
    : true)
  && ((R.has('feature', meta) && !R.propEq('paywall', allowPaywall, meta))
    ? ability.can('use', meta.feature)
    : true)
);

export default defineAbilitiesFor;
