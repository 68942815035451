import * as R from 'ramda';
import { redirect, NOT_FOUND } from 'redux-first-router';
import api from 'server/api';
import reduxFetch from 'utils/reduxFetch';
import { actions as articleActions } from './ducks/article.duck';
import { actions as relatedActions } from './ducks/related.duck';
import getResponseItems from 'utils/getResponseItems';

const route = async (dispatch, getState) => {
  const state = getState();
  const slug = R.path(['location', 'payload', 'slug'], state);

  const article = await dispatch(
    reduxFetch({
      fetch: api('article', 'get'),
      filters: { slug },
      actions: articleActions,
      onSuccess: (resp) => {
        const data = R.path(['data', '0'], resp);

        if (!data) {
          return dispatch(redirect({ type: NOT_FOUND }));
        }
      },
      onError: () => dispatch(redirect({ type: NOT_FOUND })),
      onFail: () => dispatch(redirect({ type: NOT_FOUND })),
    }),
  );

  const related = await dispatch(
    reduxFetch({
      fetch: api('article', 'get'),
      filters: {
        limit: 4,
        'exclude.slug': getResponseItems(article, 'slug'),
        // TODO: Not great
        geography: {
          label: R.path(['data', '0', 'geography', 'title'], article),
          slug: R.path(['data', '0', 'geography', 'slug'], article),
        },
      },
      actions: relatedActions,
    }),
  );

  return Promise.all([article, related]);
};

export default route;
