import * as R from 'ramda';
import pageMap from 'consts/pageMap';
import routesMap from 'state/routesMap';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { branch, renderComponent, compose, withProps } from 'recompose';
import getPageType from 'state/selectors/getPageType';
import ComponentMap from 'components/general/ComponentMap/ComponentMap';
import Paywall from 'components/utility/Paywall';

/**
 * Checks if the 'paywall' flag is enabled in route metadata.
 * This might not be necessary once Casl implements error types, where we can be
 * explicit about why someone is not allowed to do something.
 */
const getRouteMeta = createSelector(
  getPageType,
  (pageType: string) => (
    R.pathOr({}, [pageType, 'meta'], routesMap)
  ),
);

const SitePage = compose(
  withProps({
    components: pageMap,
    isLoading: false,
  }),
  connect(R.applySpec({
    page: getPageType,
    routeMeta: getRouteMeta,
  })),
  withProps({
    components: pageMap,
  }),
  branch(
    ({ routeMeta }) => R.has('paywall', routeMeta),
    renderComponent(Paywall),
  ),
)(ComponentMap);

export default SitePage;

