import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { createSelector } from 'reselect';
import getCurrentPermalink from 'selectors/getCurrentPermalink';
import track from 'react-tracking';

const withPageTracking = () => Component => {
  @track(({ ptUrl }) => ({
    url: ptUrl,
  }))
  class PageTracking extends React.Component {
    componentDidMount() {
      this.trackPage();
    }
    componentDidUpdate() {
      this.trackPage();
    }
    trackPage() {
      const { tracking, ptLocation } = this.props;
      tracking.trackEvent({
        event: 'pageView',
        location: ptLocation,
      });
    }
    render() {
      const props = R.omit(['ptUrl', 'ptLocation'], this.props);
      return <Component {...props} />;
    }
  }

  return PageTracking;
};

const getLocation = createSelector(
  R.prop('location'),
  R.applySpec({
    type: R.prop('type'),
    pathname: R.prop('pathname'),
    search: R.propOr('', 'search'),
    query: R.propOr({}, 'query'),
    payload: R.prop('payload'),
  }),
);

export default () => compose(
  connect(state => ({
    ptUrl: getCurrentPermalink(state),
    ptLocation: getLocation(state),
  })),
  withPageTracking(),
);
