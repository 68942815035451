import * as R from 'ramda';
import { createReducer } from 'redux-act';
import { createSelector } from 'reselect';
import createNewRequestActions from 'utils/createNewRequestActions';
import createLoadingReducer from 'utils/createLoadingReducer';
import createPaginationReducer from 'utils/createPaginationReducer';
import { globalize } from 'utils/duckTools';

/**
 * Creates actions/selectors/reducers for working for 'lists' of entities
 * returned from the API (e.g. articles).
 * @param {String} name Unique name on which the action types will be based.
 * Should follow the redux format of all caps, underscores, e.g. TEST_ACTION
 * @param {Array} slice The path to the slice of the state we're targeting,
 * e.g. ['screens', 'articles']
 * @returns {Object} Generated reducer/selectors/actions.
 * @property {Object} reducer The reducer for the duck.
 * @property {Object} actions The duck actions.
 * @property {Object} selectors The duck selectors.
 */
const createListDuck = (name, slice, createIndex = R.prop('id')) => {
  const actions = createNewRequestActions(name);

  const reducer = {
    data: createReducer({
      [actions.types.success]: (state, payload) => (
        R.pipe(
          R.prop('data'),
          R.indexBy(createIndex),
        )(payload)
      ),
    }, {}),
    order: createReducer({
      [actions.types.success]: (state, payload) => (
        R.pipe(
          R.prop('data'),
          R.map(createIndex),
        )(payload)
      ),
    }, []),
    pagination: createPaginationReducer(name),
    loading: createLoadingReducer(name),
  };

  const selectOrder = globalize(['order'], slice);
  const selectData = globalize(['data'], slice);
  const selectLoading = globalize(['loading'], slice);
  const selectPagination = globalize(['pagination'], slice);

  const selectInOrder = createSelector(
    selectData,
    selectOrder,
    (data, order) => (
      (data && order)
        ? order.map(id => data[id])
        : []
    ),
  );

  const selectors = {
    data: selectData,
    order: selectOrder,
    loading: selectLoading,
    pagination: selectPagination,
    inOrder: selectInOrder,
  };

  return {
    actions,
    reducer,
    selectors,
  };
};

export default createListDuck;

