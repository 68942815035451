/**
 * withOnEscape
 */
import React from 'react';
import wrapDisplayName from 'recompose/wrapDisplayName';
import keyMap from './../../utils/keyMap';

const enhanceWithOnEscape = (Component) => {
  class EnhancedComponent extends React.PureComponent {
    constructor(props) {
      super(props);
      this.handleLocalEscape = this.handleLocalEscape.bind(this);
      this.onKeyDown = this.onKeyDown.bind(this);
    }
    componentDidMount() {
      window.addEventListener('keydown', this.onKeyDown, true);
    }
    componentWillUnmount() {
      window.removeEventListener('keydown', this.onKeyDown, true);
    }
    onKeyDown(e) {
      if (keyMap('escape') === e.keyCode) {
        this.handleLocalEscape(e);
      }
    }
    handleLocalEscape(e) {
      if (typeof this.wrappedComponent.handleEscape === 'function') {
        this.wrappedComponent.handleEscape(e);
      }
    }
    render() {
      return (
        <Component
          {...this.props}
          ref={
            c => {
              if (!c) return;
              // If there is already a wrappedComponent,
              // we are chaining, so use that as the 'source'.
              this.wrappedComponent = c.wrappedComponent || c;
            }
          }
        />
      );
    }
  }

  EnhancedComponent.displayName = wrapDisplayName(Component, 'withOnEscape');

  return EnhancedComponent;
};

export default enhanceWithOnEscape;
