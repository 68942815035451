import * as R from 'ramda';

const files = require.context('./..', true, /.*\.duck.(ts|js)$/);

/**
 * A nested object containing all 'duck' files within the screens sub-folder,
 * structured to mirror their folder structure (sans 'duck' folder). This
 * creates a hierarchical reducer tree that mirrors page structure. Also avoids
 * the pain of importing each new duck as it's added.
*/
const ducks = R.pipe(
  R.map(R.applySpec({
    key: R.pipe(
      R.replace(/(ducks\/|.duck.(ts|js))/g, ''),
      R.split('/'),
      R.tail,
    ),
    val: R.pipe(
      files,
      R.ifElse(
        R.has('reducer'),
        R.prop('reducer'),
        R.prop('default'),
      ),
    ),
  })),
  R.reduce((acc, item) => (
    R.set(
      R.lensPath(item.key, acc),
      item.val,
    )(acc)
  ), {}),
)(files.keys());

export default ducks;
