import React from 'react';
import withError from './../../hoc/withError';

export const FPError = ({ hasError, message, error, children }) => {
  if (!hasError) return children;
  return (
    <div className="error-screen">
      <div className="error-screen__contents">
        <h1 className="error-screen__title">Something went wrong</h1>
        {message ? (
          <div className="error-screen__message">
            {message}
          </div>
        ) : (
          <p>
            Unfortunately something went wrong and the page encountered an error.
          </p>
        )}
      </div>
    </div>
  );
};

export default withError(FPError);
