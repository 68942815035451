import { createReducer } from 'redux-act';

/**
 * Returns a consistent pagination reducer for parts of the store with
 * paginated data.
 * @param {String} type Unique name on which the action types will be based.
 * Should follow the redux format of all caps, underscores, e.g. TEST_ACTION
 * @returns {Reducer} The generated loading reducer.
 */
const createPaginationReducer = type => createReducer({
  [`NEW_${type}_REQUEST`]: (state, payload) => (
    payload && payload.page
      ? Object.assign({}, state, {
        currentPageUI: payload.page,
      })
      : state
  ),
  [`NEW_${type}_SUCCESS`]: (state, payload) => (
    Object.assign({}, state, payload.pagination, {
      currentPageUI: payload.pagination.currentPage,
    })
  ),
}, {});

export default createPaginationReducer;
