import FPError from 'components/general/Errors/FPError';
import withPDFView from 'components/hoc/withPDFView';
import SiteLayout from 'containers/SiteLayout';
import SitePage from 'containers/SitePage';
import SiteStickyFlashes from 'containers/SiteStickyFlashes';
import SiteWrapper from 'containers/SiteWrapper';
import 'modernizr';
import React from 'react';
import { Helmet } from 'react-helmet';
import track from 'react-tracking';
import { compose } from 'recompose';
import iServer from 'utils/isServer';
import metrics from 'utils/metrics';
import OnboardingModal from 'containers/OnboardingModal';
import './../sass/styles.scss';
// import Pusher from 'components/general/Pusher/Pusher';

@track({}, { dispatch: metrics.dispatch })
class App extends React.Component {
  componentDidMount() {
    if (!iServer) {
      import('viewport-units-buggyfill').then(module => (
        module.init()
      ));
    }
  }
  render() {
    return (
      <SiteWrapper>
        <FPError>
          <Helmet titleTemplate="%s | GSI">
            <title>Global Security Insight | S-RM</title>
          </Helmet>
          <SiteLayout>
            <SitePage />
          </SiteLayout>
          <SiteStickyFlashes />
          <OnboardingModal />
          {/* <Pusher
            channel="gsi-customer-1"
            event="customer-clear-cache"
            onUpdate={d => console.log('Fired update:', d)}
          /> */}
        </FPError>
      </SiteWrapper>
    );
  }
}

export default compose(
  withPDFView(SitePage),
)(App);

