import React, { Fragment } from 'react';
import Link from 'components/general/Link/Link';
import SiteImage from 'containers/SiteImage';
import LinkProps from 'utils/ts/LinkProps';

const Profile: React.SFC<PropDef> = ({ name, photo, to }) => {
  const content = (
    <Fragment>
      {photo && (
        <div className="profile__img">
          <SiteImage
            src={photo}
            crops={[
              { w: 60, h: 60 },
              { w: 120, h: 120 },
            ]}
          />
        </div>
      )}
      <div className="profile__details">
        <span itemProp="name">{name}</span>
      </div>
    </Fragment>
  );

  return (
    <div
      className="profile"
      itemScope
      itemType="http://schema.org/Person"
    >
      {to ? (
        <Link to={to}>
          {content}
        </Link>
      ) : content }
    </div>
  );
};

interface PropDef {
  name: string;
  photo: string;
  to?: LinkProps;
}

export default Profile;

