/**
 * alertsIndexMap
 */
import * as R from 'ramda';
import reduxFetch from 'utils/reduxFetch';
import api from 'server/api';
import { getInitialAlertMapFilterValues } from '../shared/selectors/getInitialAlertFilterValues';
import * as alerts from './ducks/list.duck';
import * as detail from './ducks/detail.duck';
import * as ratings from './ducks/ratings.duck';

const route = async (dispatch, getState, { action }) => {
  const state = getState();
  const riskTypes = R.path(['location', 'query', 'riskTypes'], state);
  const contentTags = R.path(['location', 'query', 'contentTags'], state);
  const marker = R.path(['location', 'query', 'marker'], state);
  const values = getInitialAlertMapFilterValues(state);

  const thunks = {
    ratings: {
      fetch: api('geography', 'get'),
      actions: ratings.actions,
      filters: {
        type: 'country',
        limit: -1,
      },
    },
    list: {
      fetch: api('alert', 'get'),
      actions: alerts.actions,
      filters: {
        riskTypes,
        contentTags,
        limit: -1,
        start: R.path(['dateRange', 'start'], values),
        end: R.path(['dateRange', 'end'], values),
        fields: 'id,title,point,when,slug,riskType,contentTags,geography',
      },
    },
    details: {
      fetch: api('alert', 'get'),
      actions: detail.actions,
      filters: {
        id: marker,
      },
    },
  };

  const shouldFetchDetails =
    marker
    && !R.prop(marker, detail.selectors.data(state));

  const thunksToRun = R.pipe(
    R.when(
      R.always(!R.isNil(action.thunks)),
      R.pick(action.thunks),
    ),
    R.when(
      R.always(!shouldFetchDetails),
      R.omit(['details']),
    ),
  )(thunks);

  return Promise.all(
    R.pipe(
      R.map(k => dispatch(reduxFetch(k))),
      R.values,
    )(thunksToRun),
  );
};

export default route;
