import createGenericDataDuck from 'utils/createGenericDataDuck';
import { getPath } from 'utils/duckTools';

export const { reducer, actions, selectors } = createGenericDataDuck(
  'ALERTS_SINGLE_CONTENT',
  getPath(__filename),
);

export default reducer;

