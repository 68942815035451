import * as R from 'ramda';
import { redirect } from 'redux-first-router';
import qs from 'qs';
import isServer from 'utils/isServer';
import { closeNav } from 'ducks/ui';
import { clearFlashes } from 'state/ducks/flashes';
import defineAbilitiesFor, { isAllowed } from 'utils/ability';
import restoreScroll from 'redux-first-router-restore-scroll';
import routesMap from 'state/routesMap';

const queryStringOptions = { arrayFormat: 'bracket' };

export default {
  restoreScroll: restoreScroll({
    shouldUpdateScroll: (prev, locationState) => (
      // Only update scroll position automatically for type/payload changes. The
      // main motivation here is to avoid auto-scroll changes on filter updates,
      // which it turns out is really annoying.
      !R.equals(prev.type, locationState.type) ||
      !R.equals(prev.payload, locationState.payload) ||
      !R.equals(
        R.path(['query', 'page'], prev),
        R.path(['query', 'page'], locationState),
      )
    ),
  }),
  onBeforeChange: async (dispatch, getState, action) => {
    if (!isServer) {
      // Client-side only check, since it runs before user data is loaded
      // first-time
      const actionType = R.path(['action', 'type'], action);
      const routeMeta = R.pathOr({}, [actionType, 'meta'], routesMap);
      const ability = defineAbilitiesFor(R.prop('user', getState()));

      if (!isAllowed(routeMeta, ability)) {
        dispatch(redirect({ type: 'FORBIDDEN' }));
      }

      // 'Clear' flashes on page change, since they're supposed to be one-view only.
      // Don't clear flashes on server-render, since this is the first 'view' of them (if present).
      dispatch(clearFlashes());
    }

    dispatch(closeNav());
  },
  querySerializer: {
    parse: val => qs.parse(val, queryStringOptions),
    stringify: val => qs.stringify(val, queryStringOptions),
  },
};
