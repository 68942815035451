import * as R from 'ramda';
import { createSelector } from 'reselect';
import getSiteQuery from 'selectors/getSiteQuery';

export const initialValues = {
  search: '',
};

const getFilterValues = createSelector(
  getSiteQuery,
  query => (
    R.pipe(
      R.omit(['page', 'hideOnboardingModal']),
      R.merge(initialValues),
    )(query)
  ),
);

export default getFilterValues;
