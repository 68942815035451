import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import * as R from 'ramda';
import Header from 'components/general/Header/Header';
import { toggleNav, toggleSearch, closeSearch } from 'ducks/ui';
import getUserAbility from 'selectors/getUserAbility';
import getUserData from 'selectors/getUserData';
import getUserStatus from 'selectors/getUserStatus';
import getMarketingUrl from 'selectors/getMarketingUrl';

const getSiteNavActive = createSelector(
  R.path(['ui', 'siteNavActive']),
  active => active,
);

const getSiteSearchActive = createSelector(
  R.path(['ui', 'siteSearchActive']),
  active => active,
);

const getNavLinks = createSelector(
  R.pipe(
    getUserStatus,
    R.prop('authenticated'),
  ),
  (authenticated: boolean) => (
    authenticated ? ([
      {
        to: { type: 'ARTICLES_INDEX' },
        title: 'Articles',
      },
      {
        to: { type: 'MAP' },
        title: 'Map',
      },
      {
        to: { type: 'ALERTS_INDEX' },
        title: 'Alerts',
      },
      {
        to: { type: 'COUNTRIES' },
        title: 'Countries',
      },
      {
        to: {
          type: 'INFO',
          payload: {
            path: 'about-gsi',
          },
        },
        title: 'Info',
      },
    ]) : []
  ),
);

const getUserLinks = createSelector(
  getUserAbility,
  (ability) => {
    const accountActions = R.pipe(
      R.append({
        title: 'Manage Profile',
        icon: 'settings',
        to: { type: 'PREFERENCES' },
      }),
      R.when(
        R.always(
          ability.can('read', 'Organisation')
        ),
        R.append({
          title: 'Organisation',
          icon: 'users',
          to: { type: 'ORGANISATION' },
        }),
      ),
      R.append({
        title: 'Log out',
        icon: 'log-out',
        to: { type: 'LOGOUT' },
      }),
    )([]);
    return [
      {
        title: 'Account',
        actions: accountActions,
      },
    ];
  },
);

const mapStateToProps = R.applySpec({
  links: getNavLinks,
  siteNavOpen: getSiteNavActive,
  siteSearchActive: getSiteSearchActive,
  user: getUserData,
  userLinks: getUserLinks,
  marketingUrl: getMarketingUrl,
});

const mapDispatchToProps = dispatch => ({
  toggleSiteNav: () => dispatch(toggleNav()),
  toggleSearch: () => dispatch(toggleSearch()),
  closeSearch: () => dispatch(closeSearch()),
});

const SiteHeader = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Header);

export default SiteHeader;
