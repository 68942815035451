import * as R from 'ramda';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { compose } from 'recompose';
import getPageType from 'state/selectors/getPageType';
import getFormat from 'state/selectors/getFormat';
import Site from 'components/general/Site/Site';
import withPageTracking from 'components/hoc/withPageTracking';
import withUserTracking from 'components/hoc/withUserTracking';

const getTypes = createSelector(
  getPageType,
  getFormat,
  (pageType, format) => (
    R.pipe(
      R.when(
        R.always(
          R.equals('MAP', pageType),
        ),
        R.append('is-capped-sm'),
      ),
      R.unless(
        R.always(
          R.equals('web', format),
        ),
        R.append(format),
      ),
    )([])
  ),
);

const mapStateToProps = state => ({
  types: getTypes(state),
});

const SiteWrapper = compose(
  connect(mapStateToProps),
  withUserTracking(),
  withPageTracking(),
)(Site);

export default SiteWrapper;

