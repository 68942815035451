import * as R from 'ramda';
import api from 'server/api';
import reduxFetch from 'utils/reduxFetch';
import getCustomerID from 'selectors/getCustomerID';
import getSiteQuery from 'selectors/getSiteQuery';
import { actions } from './ducks/invitations.duck';
import customerThunk from './../../thunk';

const route = async (dispatch, getState) => {
  const state = getState();
  const values = getSiteQuery(state);
  const id = getCustomerID(state);

  const invitationsGet = reduxFetch({
    fetch: api('invitation', 'get'),
    actions,
    perPage: 10,
    filters: {
      customerId: id,
      ...values,
      sort: {
        by: R.prop('sort', values),
        order: R.prop('order', values),
      },
    },
  });

  return Promise.all([
    dispatch(customerThunk),
    dispatch(invitationsGet),
  ]);
};

export default route;
