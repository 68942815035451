import * as R from 'ramda';
import getSiteQuery from 'selectors/getSiteQuery';

const getFormat = R.pipe(
  getSiteQuery,
  R.propOr('web', 'format'),
);

export default getFormat;

