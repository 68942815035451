import * as R from 'ramda';
import api from 'server/api';
import reduxFetch from 'utils/reduxFetch';
import getSiteQuery from 'selectors/getSiteQuery';
import { actions } from './ducks/invitations.duck';

const route = async (dispatch, getState) => {
  const state = getState();
  const values = getSiteQuery(state);

  return dispatch(
    reduxFetch({
      fetch: api('invitation', 'get'),
      actions,
      perPage: 10,
      filters: {
        ...values,
        sort: {
          by: R.prop('sort', values),
          order: R.prop('order', values),
        },
      },
    }),
  );
};

export default route;
