import * as R from 'ramda';
import { compose, withProps } from 'recompose';
import { connect } from 'react-redux';
import layoutMap, { defaultLayout } from 'consts/layoutMap';
import getPageType from 'state/selectors/getPageType';
import ComponentMap from 'components/general/ComponentMap/ComponentMap';

const SiteLayout = compose(
  withProps({
    components: layoutMap,
    fallback: defaultLayout,
  }),
  connect(R.applySpec({
    page: getPageType,
  })),
)(ComponentMap);

export default SiteLayout;
