import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { nest, withProps } from 'recompose';

export class Portal extends React.Component {
  constructor() {
    super();

    this.state = {
      loaded: false,
    };

    this.el = null;
    this.root = null;
  }

  componentDidMount() {
    this.el = document.createElement('div');
    this.root = this.props.selector ? document.querySelector('#modals') : null;

    this.root.appendChild(this.el);

    // Necessary for SSR
    this.setState({ loaded: true });
  }

  componentWillUnmount() {
    this.root.removeChild(this.el);
  }

  render() {
    if (!this.state.loaded) return null;

    return ReactDOM.createPortal(
      this.props.children,
      this.el,
    );
  }
}

Portal.propTypes = {
  // A CSS selector
  selector: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

const withPortal = config => Component => (
  withProps({
    selector: config.selector,
  })(nest(Portal, Component))
);

export default withPortal;
