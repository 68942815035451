import * as R from 'ramda';
import config from 'utils/config';
import MetricVendor from 'utils/tracking/MetricVendor';
import isServer from 'utils/isServer';

/**
 * Listens for handled error events and passes them on to Bugsnag
 */
class Bugsnag extends MetricVendor {
  static lib;
  static apiKey = config(['bugsnag', 'apiKey']);
  static release = config(['bugsnag', 'release']);
  private api;
  constructor(props) {
    super(props);

    // Only setup bugsnag-js client-side, node is a separate lib
    if (!isServer) this.setupAPI();
  }
  setupAPI() {
    // Lazy-load and only do this client-side
    import('bugsnag-js').then((bugsnag) => {
      Bugsnag.lib = bugsnag;
      // Setup instance
      this.api = Bugsnag.lib({
        apiKey: Bugsnag.apiKey,
        releaseStage: Bugsnag.release,
        appVersion: '8.9.1',
      });
    });
  }
  track(data) {
    const { error, user } = data;

    // Pass error through to bugsnag-js
    this.api.notify(new Error(`Handled: ${error.message}`), {
      user,
      metaData: {
        // React component stack info
        info: error.info,
      },
      beforeSend: (report) => {
        // Re-format stacktrace for bugsnag (from stacktrace.js)
        report.stacktrace = R.map(R.applySpec({
          file: R.prop('fileName'),
          method: R.prop('functionName'),
          lineNumber: R.prop('lineNumber'),
          columnNumber: R.prop('columnNumber'),
        }))(error.stack);
      },
    });
  }
}

export default Bugsnag;
