import * as R from 'ramda';
import { redirect, NOT_FOUND } from 'redux-first-router';
import reduxFetch from 'utils/reduxFetch';
import api from 'server/api';
import getPayload from 'selectors/getPayload';
import getRiskTypes from 'selectors/getRiskTypes';
import forecastRiskTypes from 'consts/forecastRiskTypes';
import * as geographyDuck from './ducks/geography.duck';
import * as regionDuck from './ducks/region.duck';
import * as forecastDuck from './ducks/forecast.duck';
import * as ratingsDuck from './ducks/ratings.duck';
import * as alertsDuck from './ducks/alerts.duck';

/**
 * Attempts to get the matching riskType for the provided forecast slug.
 * The riskType returned is just the camel-case ID, e.g. 'riskExtortion',
 * which we then need to use to get the actual slug from redux.
 */
const getMatchingRiskTypeName: (forecastSlug: string) => string = forecastSlug => R.pipe(
  R.find(R.propEq('slug', forecastSlug)),
  R.prop('riskType'),
)(forecastRiskTypes);

const route = async (dispatch, getState) => {
  const state = getState();
  const payload = getPayload(state);
  const { geography, region, riskType } = payload;

  const typeMatch = R.find(
    R.propEq('slug', riskType),
    forecastRiskTypes,
  );

  // The slug of the corresponding 'risk type', so we can get relevant alerts.
  const matchingRiskType = R.pipe(
    getRiskTypes,
    R.path([getMatchingRiskTypeName(riskType), 'slug']),
  )(state);

  // If the requested riskType doesn't exist, redirect the user to the overview.
  if (riskType && !typeMatch) {
    dispatch(redirect({
      type: 'COUNTRIES_GEOGRAPHY',
      payload: { geography },
    }));
  }

  const thunks = {
    geography: {
      fetch: api('geography', 'get'),
      filters: {
        slug: geography,
        type: 'country',
      },
      actions: geographyDuck.actions,
      onSuccess: (resp) => {
        const data = R.path(['data', '0'], resp);

        if (!data) {
          return dispatch(redirect({ type: NOT_FOUND }));
        }
      },
    },
    region: {
      fetch: api('geography', 'get'),
      filters: {
        slug: region,
        type: 'countryRegion',
      },
      actions: regionDuck.actions,
    },
    forecast: {
      fetch: api('forecast', 'get'),
      filters: {
        slug: region || geography,
        fields: `geography.slug,content.${R.prop('id', typeMatch || 'overview')}`,
      },
      actions: forecastDuck.actions,
      requestID: 'GEOG_RISK_TYPE',
    },
    ratings: {
      fetch: api('forecast', 'get'),
      filters: {
        slug: region || geography,
        fields: 'geography.risk',
      },
      actions: ratingsDuck.actions,
      requestID: 'GEOG_RISK_RATINGS',
    },
    alerts: {
      fetch: api('alert', 'get'),
      perPage: 5,
      filters: {
        fillToLimit: true,
        'geography.slug': region || geography,
        'risktype.slug': riskType ? matchingRiskType : undefined,
      },
      actions: alertsDuck.actions,
    },
  };

  const thunksToRun = R.pipe(
    // If we already have geography data, don't bother re-fetching - mostly
    // static info.
    R.when(
      R.both(
        R.always(
          !R.isEmpty(
            geographyDuck.selectors.data(state),
          ),
        ),
        // Only omit if we're on the same geography.
        R.always(
          R.equals(
            geography,
            R.prop('slug', geographyDuck.selectors.data(state)),
          ),
        ),
      ),
      R.omit(['geography']),
    ),
    R.when(
      R.either(
        R.always(
          R.isNil(region),
        ),
        R.both(
          R.always(
            !R.isEmpty(
              regionDuck.selectors.data(state),
            ),
          ),
          R.always(
            R.equals(
              region,
              R.prop('slug', regionDuck.selectors.data(state)),
            ),
          ),
        ),
      ),
      R.omit(['region']),
    ),
  )(thunks);

  return Promise.all(
    R.pipe(
      R.values,
      R.map(k => dispatch(reduxFetch(k))),
      R.values,
    )(thunksToRun),
  );
};

export default route;
