/**
 * Store
 * The full Redux application store (client-side).
 */
import { connectRoutes } from 'redux-first-router';
import ReduxThunk from 'redux-thunk';
import { combineReducersEnhanced } from 'combine-reducers-enhanced';
import { compose, createStore, applyMiddleware, combineReducers } from 'redux';
import { types } from 'redux-act';

import { composeWithDevTools } from 'redux-devtools-extension';
import routesMap from './routesMap';
import routeOptions from './routeOptions';

// All reducers or 'ducks/'
import * as reducers from './ducks';

if (process.env.NODE_ENV === 'development') {
  // https://github.com/pauldijou/redux-act/blob/master/README.md#types
  types.disableChecking();
}

// The final store
const store = (history, preLoadedState) => {
  // De-structure containers from RF-Router
  const {
    reducer,
    middleware,
    enhancer,
    thunk,
  } = connectRoutes(history, routesMap, routeOptions);

  // Compose enhancers with/without redux dev-tools
  // depending on if we're in-browser or on the server.
  const composeEnhancers = (...args) => (
    typeof window !== 'undefined'
      // Set up Redux Dev-tools
      ? composeWithDevTools(enhancer, ...args)
      : compose(...args)
  );

  const rootReducer = combineReducersEnhanced({ location: reducer, ...reducers });
  const middlewares = [middleware, ReduxThunk];

  // Add logging in development
  if (process.env.NODE_ENV !== 'production' && typeof window !== 'undefined') {
    // middlewares.push(createLogger());
  }

  const newStore = createStore(
    rootReducer,
    preLoadedState,
    composeEnhancers(
      enhancer,
      applyMiddleware(...middlewares),
    ),
  );

  if (module.hot && process.env.NODE_ENV === 'development') {
    module.hot.accept('./ducks', () => {
      const rootReducer = combineReducers({ ...reducers, location: reducer });
      store.replaceReducer(rootReducer);
    });
  }

  return {
    thunk,
    store: newStore,
  };
};

export default store;
