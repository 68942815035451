import * as R from 'ramda';
import { redirect, NOT_FOUND } from 'redux-first-router';
import api from 'server/api';
import reduxFetch from 'utils/reduxFetch';
import { actions } from './ducks/user.duck';

const route = async (dispatch, getState) => {
  const state = getState();
  const id: string|number = R.path(['location', 'payload', 'userId'], state);

  return dispatch(
    reduxFetch({
      id,
      actions,
      fetch: api('user', 'get'),
      onError: () => dispatch(redirect({ type: NOT_FOUND })),
    }),
  );
};

export default route;
