import * as R from 'ramda';
import { createSelector } from 'reselect';
import createListDuck from 'utils/createListDuck';
import { getPath } from 'utils/duckTools';

const alertDuck = createListDuck(
  'ALERTS_INDEX_LIST',
  getPath(__filename),
);

const hasRegion = R.both(
  R.has('parent'),
  R.pathEq(['parent', 'geographyType'], 'country'),
);

const getItems = createSelector(
  alertDuck.selectors.inOrder,
  R.map(elem => (
    R.mergeAll([elem, {
      geography: {
        title: elem.geography.title,
        href: {
          type: 'COUNTRIES_GEOGRAPHY',
          payload: R.applySpec({
            geography: R.ifElse(
              hasRegion,
              R.path(['parent', 'slug']),
              R.prop('slug'),
            ),
            region: R.ifElse(
              hasRegion,
              R.prop('slug'),
              R.always(null),
            ),
          })(elem.geography),
        },
      },
    }])
  )),
);

export const actions = {
  ra: alertDuck.actions,
};

export const selectors = R.merge({
  items: getItems,
}, alertDuck.selectors);

export default alertDuck.reducer;
