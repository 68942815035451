/**
 * HTTP status code so we can return it in render.js
 */
import * as R from 'ramda';
import { createAction, createReducer } from 'redux-act';

/**
 * Actions
 */
export const setStatus = createAction(
  'SET_STATUS',
  status => ({ status }),
);

/**
 * Selectors
 */
export const getStatus = R.prop('status');

/**
 * Reducer
 */
const status = createReducer({
  [setStatus]: (state, { status: statusCode }) => {
    // 403 is more appropraite for page response
    if (statusCode === 401) return 403;
    return statusCode;
  },
  NOT_FOUND: () => 404,
  FORBIDDEN: () => 403,
}, 200);

/**
 * Full reducer
 */
export default status;
