import React from 'react';

const DateTime: React.SFC<DateTimeProps> = ({
  datetime,
  human,
  iso8601,
  className,
}) => (
  <time dateTime={datetime} className={className}>
    {human}
    <meta
      itemProp="datePublished"
      content={iso8601}
    />
  </time>
);

export interface DateTimeProps {
  datetime: string;
  human: string;
  iso8601: string;
  className: string;
}

export default DateTime;
