import * as R from 'ramda';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import Image from 'components/general/Image/Image';
import getFormat from 'selectors/getFormat';

const getShouldLazyLoad = createSelector(
  getFormat,
  R.equals('web'),
);

const SiteImage = connect(state => ({
  lazyload: false,
}))(Image);

export default SiteImage;
