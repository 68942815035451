import Matomo from 'utils/tracking/Matomo';
import Bugsnag from 'utils/tracking/Bugsnag';

// Analytics 'vendors'/services
export const vendors = [
  {
    api: new Matomo({
      name: 'Matomo',
      listenFor: ['pageView'],
    }),
  },
  {
    api: new Bugsnag({
      name: 'Bugsnag',
      listenFor: ['error'],
    }),
  },
];

/**
 * Handles dispatch of analytics events to vendors
 */
const metrics = (() => {
  const dispatch = (event) => {
    // Don't bother in dev
    // if (isDev) return;
    vendors.forEach((vendor) => {
      vendor.api.notify(event);
    });
  };

  return {
    dispatch,
  };
})();

export default metrics;
