import * as R from 'ramda';
import { createSelector } from 'reselect';
import startOfToday from 'date-fns/start_of_today';
import subDays from 'date-fns/sub_days';
import format from 'date-fns/format';
import getSiteQuery from 'selectors/getSiteQuery';

export const defaultValues = {
  shading: 'riskOverall',
};

const omitParams = R.omit(['page', 'marker', 'hideOnboardingModal']);

export const getInitialAlertIndexFilterValues = createSelector(
  getSiteQuery,
  R.pipe(
    omitParams,
    R.merge({
      shading: 'riskOverall',
    }),
  ),
);

export const getInitialAlertMapFilterValues = createSelector(
  getSiteQuery,
  R.pipe(
    omitParams,
    R.merge({
      shading: 'riskOverall',
      dateRange: {
        start: format(subDays(startOfToday(), 365), 'YYYY-MM-DD'),
        end: '',
      },
    }),
  ),
);
