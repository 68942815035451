import React from 'react';
import Link from 'components/general/Link/Link';
import InlineSVG from 'components/general/InlineSVG/InlineSVG';
import Placeholder from 'components/general/Placeholder/Placeholder';
import LinkProps from 'utils/ts/LinkProps';
import Icon from 'components/general/Icon/Icon';

const Footer: React.SFC<PropDef> = ({ navLinks }) => (
  <footer className="site-footer">
    <div className="site-footer__inner">
      <div className="site-footer__logo site-footer__section">
        <a href="https://www.s-rminform.com/" target="_blank" rel="noopener noreferrer">
          <Placeholder width={300} height={127}>
            <InlineSVG name="srm-logo-white" />
          </Placeholder>
        </a>
      </div>
      <div className="site-footer__contact site-footer__section">
        <a href="tel:442037639595"><Icon name="phone-white" type="solid" types={['push-right']} />+44 (0)20 3763 9595</a>
        <a href="mailto:gsi@s-rminform.com"><Icon name="email-white" type="solid" types={['push-right']} />gsi@s-rminform.com</a>
        <a href="https://twitter.com/SRMInform" target="_blank"><Icon name="twitter-white" type="solid" types={['push-right']} />@SRMInform</a>
        <a href="https://www.linkedin.com/company/s-rm/" target="_blank"><Icon name="linkedin-white" type="solid" types={['push-right']} />S-RM</a>
      </div>
      <ul className="site-footer__section site-footer__nav">
        {navLinks.map(link => (
          <li className="site-footer__nav-item" key={link.title}>
            <Link to={link.to}>
              {link.title}
            </Link>
          </li>
        ))}
      </ul>
      <small className="site-footer__copyright site-footer__section">
        &copy; S-RM Intelligence and Risk Consulting {new Date().getFullYear()}
      </small>
    </div>
  </footer>
);

Footer.defaultProps = {
  navLinks: [],
};

interface PropDef {
  navLinks: {
    title: string,
    to: LinkProps,
  }[];
}

export default Footer;

