import * as R from 'ramda';
import reduxFetch from 'utils/reduxFetch';
import api from 'server/api';
import getSiteQuery from 'state/selectors/getSiteQuery';
import { actions } from './ducks/forecast.duck';

const route = async (dispatch, getState) => {
  const state = getState();
  const query = getSiteQuery(state);

  const thunks = {
    forecast: {
      actions,
      fetch: api('forecast', 'get'),
      perPage: 40,
      filters: R.applySpec({
        ['geography.type']: R.unless(
          R.pipe(
            R.prop('geographies'),
            R.both(
              R.complement(R.isNil),
              R.complement(R.isEmpty),
            ),
          ),
          R.always('country'),
        ),
        ['geography.slug']: R.pipe(
          R.propOr([], 'geographies'),
          R.map(R.prop('value')),
          R.join(','),
        ),
        sort: {
          by: R.prop('sort'),
          order: R.prop('order'),
        },
      })(query),
    },
  };

  return Promise.all(
    R.pipe(
      R.values,
      R.map(k => dispatch(reduxFetch(k))),
      R.values,
    )(thunks),
  );
};

export default route;
