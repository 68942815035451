import * as Stacktrace from 'stacktrace-js';

/**
 * Creates a react-tracking error event. Can't just pass the error through,
 * since react-tracking will ignore/remove it.
 */
const createErrorEvent = async (error, info?) => {
  const stack = await Stacktrace.fromError(error);

  return {
    event: 'error',
    error: {
      info,
      stack,
      message: error.message,
    },
  };
};

export default createErrorEvent;
