import { createReducer } from 'redux-act';
import loadingState from 'utils/ts/LoadingStateType';

/**
 * Returns a consistent loading-state reducer for parts of the store with
 * async data.
 * @param type Unique name on which the action types will be based.
 * Should follow the redux format of all caps, underscores, e.g. TEST_ACTION
 */
const createLoadingReducer = (type: string) => {
  const defaultState: loadingState = {
    state: 'NOT_LOADED',
    errors: [],
  };

  const reducer = createReducer<typeof defaultState>({}, defaultState);

  reducer.on(`NEW_${type}_REQUEST`, state => ({
    ...state,
    state: 'LOADING',
    errors: [],
  }));

  reducer.on(`NEW_${type}_SUCCESS`, state => ({
    ...state,
    state: 'SUCCESS',
    errors: [],
  }));

  reducer.on(`NEW_${type}_FAILURE`, (state, payload = {}) => ({
    ...state,
    state: 'FAILURE',
    errors: payload.message || 'There was an error loading.',
  }));

  return reducer;
};

export default createLoadingReducer;
