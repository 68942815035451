import React from 'react';
import classNames from 'classnames';

const Site: React.SFC<SitePropDef> = ({ types, children }) => (
  <div
    className={classNames(
      'site',
      types.map(type => `site--${type}`,
    ))}
  >
    <div className="site__inner">
      {children}
    </div>
  </div>
);

Site.defaultProps = {
  types: [],
};

interface SitePropDef {
  types?: string[];
}

export default Site;
