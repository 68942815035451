import api from 'server/api';
import reduxFetch from 'utils/reduxFetch';
import { getInitialAlertIndexFilterValues } from '../shared/selectors/getInitialAlertFilterValues';
import { actions } from './ducks/list.duck';
import alertQueryToAPI from './utils/alertQueryToApi';

const route = async (dispatch, getState) => {
  const state = getState();
  const values = getInitialAlertIndexFilterValues(state);

  return dispatch(
    reduxFetch({
      fetch: api('alert', 'get'),
      actions: actions.ra,
      perPage: 12,
      requestID: 'alerts_primary',
      filters: alertQueryToAPI(values),
    }),
  );
};

export default route;
