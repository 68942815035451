import createTableDuck from 'utils/createTableDuck';
import { getPath } from 'utils/duckTools';

export const subscriptions = createTableDuck(
  'MANAGE_SUBSCRIPTIONS_LIST',
  getPath(__filename),
);

export const { actions, reducer, selectors } = subscriptions;

export default reducer;

