import Generic from 'components/siteLayouts/Generic';
import Island from 'components/siteLayouts/Island';

const layouts = {
  HOME: Island,
  LOGIN: Island,
  FORGOT_PASSWORD: Island,
  ACCEPT_INVITE: Island,
  REGISTER_BUSINESS_INTEREST: Island,
};

export const defaultLayout = Generic;

export default layouts;
