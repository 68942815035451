import './polyfills';
import App from 'components/App';
import createHistory from 'history/createBrowserHistory';
import React from 'react';
import ReactDOM from 'react-dom';
import Loadable from 'react-loadable';
import { Provider } from 'react-redux';
import configureStore from 'state/configureStore';

// Create a new history instance
const history = createHistory();

// Set up store
const { store } = configureStore(history, window.REDUX_STATE);

const render = (AppComp) => {
  ReactDOM.hydrate(
    <Provider store={store}>
      <AppComp />
    </Provider>,
    document.querySelector('#app'),
  );
};

Loadable.preloadReady().then(() => {
  render(App);
});

if (module.hot && process.env.NODE_ENV === 'development') {
  module.hot.accept('./components/App', () => {
    const App = require('./components/App').default;
    render(App);
  });
}