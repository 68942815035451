import * as R from 'ramda';
import loadjs from 'loadjs';
import config from 'utils/config';
import MetricVendor from 'utils/tracking/MetricVendor';
import isServer from 'utils/isServer';

/**
 * Much like standard GA, Matomo pollutes window and uses a global array-like
 * interface to register events/method calls ahead of the library being loaded.
 */
declare global {
  interface Window {
    _paq: (string|number)[][];
  }
}

class Matomo extends MetricVendor {
  constructor(props) {
    super(props);
    this.env = ['development', 'staging', 'production'];

    if (!isServer) this.setupAPI();
  }
  setupAPI() {
    // Set up global tracker settings
    window._paq = window._paq || [];
    window._paq.push(['setTrackerUrl', `https://analytics.s-rminform.com/matomo.php`]);
    window._paq.push(['setSiteId', config(['matomo', 'siteId'])]);

    // Load tracker JS
    loadjs([`https://analytics.s-rminform.com/matomo.js`]);
  }
  track(data) {
    const { event, user, location } = data;

    // Update user id
    if (user) {
      const { customer } = user;

      let subscription_id = null;
      if (customer.subscription) {
        subscription_id = customer.subscription.subscriptionId;
      }

      const customVariables = [
        {
          id: 1,
          name: 'customer_id',
          value: customer.id,
        },
        {
          id: 2,
          name: 'customer_name',
          value: customer.name,
        },
        {
          id: 4,
          name: 'is_paid_user',
          value: R.pipe(
            R.prop('flags'),
            R.contains('PAID_DATA'),
          )(user),
        },
        {
          id: 5,
          name: 'subscription_id',
          value: subscription_id,
        },
      ];

      window._paq.push(['setUserId', user.email]);

      customVariables.forEach(({ id, name, value }) => {
        window._paq.push(['setCustomVariable', id, name, value, 'visit']);
      });
    }

    // Page view tracking
    if (event === 'pageView') {
      window._paq.push(['setGenerationTimeMs', 0]);
      window._paq.push([
        'setCustomUrl',
        `${location.pathname}${location.search ? `?${location.search}` : ''}`,
      ]);
      window._paq.push(['setDocumentTitle', location.type]);
      window._paq.push(['trackPageView']);
    }
  }
}

export default Matomo;
