import React, { Fragment } from 'react';
import SiteHeader from 'containers/SiteHeader';
import Footer from 'components/general/Footer/Footer';

const Generic = ({ children }) => (
  <Fragment>
    <SiteHeader />
    <div className="site__main">
      {children}
    </div>
    <Footer
      navLinks={[
        {
          title: 'Privacy & cookies',
          to: {
            type: 'INFO',
            payload: {
              path: 'privacy-cookies',
            },
          },
        },
        {
          title: 'Terms of use',
          to: {
            type: 'INFO',
            payload: {
              path: 'terms-of-use',
            },
          },
        },
      ]}
    />
  </Fragment>
);

export default Generic;

