import * as R from 'ramda';
import StateType from 'utils/ts/StateType';

interface getSiteQuery {
  (state: StateType): StateType;
}

/**
 * Gets the site query object.
 */
const getSiteQuery: getSiteQuery = R.pathOr({}, ['location', 'query']);

export default getSiteQuery;
