/**
 * Dimmer
 * A dark overlay.
 */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Dimmer = (props) => {
  const {
    children,
    fixed,
    isActive,
    onClose,
    closeOnClick,
    className,
  } = props;

  const classes = classNames('dimmer', className, {
    'dimmer--is-active': isActive,
    'dimmer--is-fixed': fixed,
  });

  return (
    <div
      className={classes}
      onClick={closeOnClick ? onClose : null}
    >
      {children}
    </div>
  );
};

Dimmer.defaultProps = {
  isActive: false,
  closeOnClick: true,
  className: '',
  fixed: false,
};

Dimmer.propTypes = {
  fixed: PropTypes.bool,
  children: PropTypes.node,
  onClose: PropTypes.func,
  isActive: PropTypes.bool,
  closeOnClick: PropTypes.bool,
  className: PropTypes.string,
};

export default Dimmer;
