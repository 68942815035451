import * as R from 'ramda';

/**
 * Risk rating types, corresponding to rating fields on geographies, e.g.
 * https://admin.staging.gsi.s-rminform.com/admin/entries/geography/149-afghanistan
 */
const riskRatingCategories: RiskRatingCategory[] = [
  {
    id: 'riskOverall',
    title: 'Overall travel',
  },
  {
    id: 'riskKidnapForRansom',
    title: 'Kidnap for ransom',
  },
  {
    id: 'riskExtortion',
    title: 'Extortion',
  },
  {
    id: 'riskWrongfulDetention',
    title: 'Wrongful detention',
  },
  {
    id: 'riskPoliticalEvacuation',
    title: 'Political evacuation',
  },
  {
    id: 'riskCrime',
    title: 'Crime',
  },
  {
    id: 'riskWar',
    title: 'War',
  },
  {
    id: 'riskTerrorism',
    title: 'Terrorism',
  },
  {
    id: 'riskCivilUnrest',
    title: 'Civil unrest',
  },
  {
    id: 'riskPoliticalViolence',
    title: 'Political violence',
  },
  {
    id: 'riskPvOverall',
    title: 'PV Overall',
  },
];

interface RiskRatingCategory {
  id: string;
  title: string;
}

export const riskRatingCategoriesAsOptions = R.map(R.applySpec({
  label: R.prop('title'),
  value: R.prop('id'),
}))(riskRatingCategories);

export default riskRatingCategories;
