import React from 'react';
import InlineSVG from 'components/general/InlineSVG/InlineSVG';
import classNames from 'classnames';

const Spinner: React.SFC<PropDef> = ({ types, className }) => (
  <InlineSVG
    name="spinner"
    className={classNames(
      'spinner',
      className,
      ...types.map(type => `spinner--${type}`),
    )}
  />
);

Spinner.defaultProps = {
  className: '',
  types: [],
};

interface PropDef {
  types?: string[];
  className?: string;
}

export default Spinner;
