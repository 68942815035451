import * as R from 'ramda';
import axios from 'axios';
import getAccessToken from 'selectors/getAccessToken';
import toPaginationData from 'utils/toPaginationData';
import queryToAPI from 'utils/queryToAPI';
import noop from 'utils/noop';
import { apiReturnType } from 'server/api';
import { setStatus } from '../state/ducks/status';

/**
 * An index of request cancel-tokens for in-flight requests (by requestID).
 */
const requests = {};

/**
 * reduxFetch options.
 */
interface options {
  /** An instance of fetchData with the first two args satisfied. */
  fetch: apiReturnType;
  /** Redux action creators. */
  actions?: {
    request?(object),
    success?(object),
    failure?(object),
  };
  /** The ID of the entity, e.g. user ID. */
  id?: number|string;
  /** The JSON request body. */
  body?: object;
  /** If we want to paginate. */
  perPage?: number;
  /** Query params. */
  filters?: object;
  /** Callback before the request. */
  onBefore?(any);
  /** Callback after a successful request. */
  onSuccess?(any);
  /** Callback after an unsuccessful request. */
  onError?(any);
  /** Provides the ability to mutate the result before being added to redux */
  processStoreData?(object);
  /** A unique ID for this request so we can chart duplicates in-flight. */
  requestID?: string;
  /** If it's the primary page request, i.e. mission critical */
  primary?: boolean;
}

/**
 * Used for all requests to the GSI API that will affect state.
 * Automatically receives auth token and dispatches redux actions for the
 * appropriate event (before, success, failure). Also automatically adds in
 * pagination data and processes query params (`options.filters`).
 */
const reduxFetch = ({
  id,
  body,
  perPage,
  onBefore = noop,
  onSuccess = noop,
  onError = noop,
  filters = {},
  fetch: fetchFn,
  actions = {},
  primary,
  requestID,
  processStoreData = data => data,
}: options) => (
  async (dispatch, getState) => {
    // What we'll send back at the end
    let resp;
    const state = getState();

    // Its tempting to send the idToken instead - but we should never do this:
    // https://auth0.com/docs/api-auth/why-use-access-tokens-to-secure-apis
    const token = getAccessToken(state);
    const pagination = toPaginationData(state, perPage);

    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    if (requestID) {
      if (requests[requestID]) {
        requests[requestID]
          .cancel(`Existing request for ${requestID} cancelled`);
      }
      requests[requestID] = source;
    }

    // If we have pagination data, put it in the request so we can
    // (optionally) update state early.
    if (actions.request) {
      dispatch(
        actions.request({
          payload: {
            ...(perPage ? { page: pagination.currentPage } : {}),
            ...(requestID ? { cancelToken: source.token } : {}),
          },
        }),
      );
    }

    onBefore();

    // Will catch network errors, but not non-200's
    try {
      // Used to combine and process filter query parameters
      const combinedFilters = R.pipe(
        R.mergeAll,
        R.mapObjIndexed((val, k) => queryToAPI(k, val)),
        R.values,
        R.mergeAll,
      )([
        filters,
        // Add in optional pagination filter
        perPage ? { page: pagination.query } : null,
      ]);

      // Start the request
      const newData = await fetchFn({
        auth: token,
        query: combinedFilters,
        cancelToken: source.token,
        id: id || null,
        body: body || null,
      });

      // If we get a 200
      if (newData.ok) {
        const totalResults = newData.headers
          ? R.prop('x-total-count', newData.headers)
          : null;

        onSuccess(newData);

        if (actions.success) {
          dispatch(
            actions.success({
              payload: R.merge(
                { data: processStoreData(newData.data) },
                // Add in pagination data if we have a 'totalResults' header
                totalResults
                  ? { pagination: pagination.state(totalResults) }
                  : {},
              ),
            }),
          );
        }
        // Only fire if what we've got back isn't an AJAX cancellation.
      } else if (!(newData instanceof axios.Cancel) && !R.pathEq(['data', 'errors', '0', 'type'], 'cancel')) {
        // Don't actually throw an error, since we want to pass an object back
        console.log('firing on error');
        onError(newData);
        if (actions.failure) {
          dispatch(
            actions.failure(
              R.path(['data', 'errors'], newData) || 'An Error occurred',
            ),
          );
        }
      }

      // If this is the 'primary' request, i.e. page critical...
      if (primary) {
        // Update HTTP status code
        dispatch(setStatus(newData.status));
      }

      resp = {
        data: newData.data,
        ok: newData.status === 200,
        status: newData.status,
      };
    } catch (e) {
      console.error(e);
      if (actions.failure) {
        dispatch(
          actions.failure('Unable to fetch data.'),
        );
      }
      resp = e;
    }

    return Promise.resolve(resp);
  }
);

export default reduxFetch;

