import * as R from 'ramda';
import { redirect, NOT_FOUND } from 'redux-first-router';
import reduxFetch from 'utils/reduxFetch';
import api from 'server/api';
import getPayload from 'selectors/getPayload';
import getSiteQuery from 'selectors/getSiteQuery';
import forecastRiskTypes from 'consts/forecastRiskTypes';
import riskRatingCategories from 'consts/riskRatingCategories';
import * as forecastDuck from './ducks/forecast.duck';
import * as ratingsDuck from './ducks/ratings.duck';

const forecastIDs = R.map(
  R.prop('id'),
)(forecastRiskTypes);

const riskRatingIDs = R.map(
  R.prop('id'),
)(riskRatingCategories);

const route = async (dispatch, getState) => {
  const state = getState();
  const query = getSiteQuery(state);
  const payload = getPayload(state);
  const slug = payload.region || payload.geography; 

  // This is a PDF only endpoint
  if (query.format !== 'pdf') {
    dispatch(redirect({ type: NOT_FOUND }));
  }

  // Get the content sections to show, either from the url param 'sections', or
  // all of them generated from forecastRiskTypes.
  const sectionFields = R.pipe(
    R.map(item => `content.${item}`),
    R.join(','),
  )(query.sections || forecastIDs);

  const thunksToRun = {
    forecast: {
      fetch: api('forecast', 'get'),
      filters: {
        slug,
        fields: `geography,${sectionFields}`,
      },
      actions: forecastDuck.actions,
      requestID: 'GEOG_RISK_TYPE_REPORT',
    },
    ratings: {
      fetch: api('forecast', 'get'),
      filters: {
        slug,
        fields: 'geography.risk',
      },
      actions: ratingsDuck.actions,
      requestID: 'GEOG_RISK_RATINGS_REPORT',
      processStoreData: (data) => {
        // @todo Bit messy, but will do for now. Essentially excludes risk types
        // that weren't selected. API doesn't currently support this type of 
        // filtering.
        const riskData = R.path(['0', 'geography', 'risk'], data);
        const filtered = R.pick(query.ratings || riskRatingIDs, riskData);

        return [R.merge(data[0], {
          geography: {
            risk: filtered,
          },
        })];
      },
    },
  };

  return Promise.all(
    R.pipe(
      R.values,
      R.map(k => dispatch(reduxFetch(k))),
      R.values,
    )(thunksToRun),
  );
};

export default route;
