/**
 * logout
 */
import { logout } from 'ducks/user';

const route = async (dispatch) => {
  dispatch(logout());
};

export default route;
