/**
 * Inline SVG-sprite icon
 */
import React from 'react';
import { onlyUpdateForKeys } from 'recompose';
import classNames from 'classnames';
import InlineSVG from 'components/general/InlineSVG/InlineSVG';

const getClassName = (className = '', types = []) => (
  classNames(
    'icon',
    className,
    types.map(type => `icon--${type}`),
  )
);

const Icon: React.SFC<IconProps> = ({
  name,
  type,
  className,
  types,
  decorative,
}) => (
  <InlineSVG
    name={`${name}-${type}`}
    className={getClassName(className, types)}
    decorative={decorative}
  />
);

Icon.defaultProps = {
  type: 'line',
  className: '',
};

export interface IconProps {
  name: string;
  type?: 'line' | 'solid';
  types?: string[];
  className?: string;
  rotate?: string;
  decorative?: boolean;
}


export default onlyUpdateForKeys(['name'])(Icon);

