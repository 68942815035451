/**
 * Creates basic redux action set for an AJAX call.
 * @param {String} type Unique name on which the action types will be based.
 * @returns {Object}
 * @property {Object} types Contains action types as strings so they can be
 * directly referenced by others (e.g. a reducer). Avoids typos.
 * @property {Function} request The initial request action-creator.
 * @property {Function} success The successful request action-creator.
 * @property {Function} failure The failure/error action-creator.
 */
const createNewRequestActions = (type) => {
  const types = {
    request: `NEW_${type}_REQUEST`,
    success: `NEW_${type}_SUCCESS`,
    failure: `NEW_${type}_FAILURE`,
  };

  return {
    types,
    request: data => ({
      type: types.request,
      ...data,
    }),
    success: data => ({
      type: types.success,
      ...data,
    }),
    failure: ({ message }) => ({
      type: types.failure,
      message,
    }),
  };
};

export default createNewRequestActions;
