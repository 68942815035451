import * as R from 'ramda';
/**
 * Helper to contain pagination data for producing the API query and the
 * Redux state object. Required since pagination calculations take place
 * client-side.
 * @param {Object} state - Redux store.
 * @param {Number} perPage=6 - Number of items per page.
 * @returns {Object} Pagination data that includes state info.
 * @property {Number} currentPage - The number of the current page.
 * @property {Object} query
 * @property {Number} query.perPage - How many results there are per page.
 * @property {Number} query.offset - What the current offset is.
 * @property {function(totalItems: Number): Object} data.state - Function
 * that takes the total item count (from {@link fetchData} {@link Headers})
 * and returns further state info.
 */
const toPaginationData = (state, perPage = 6) => {
  const currentPage = R.path(['location', 'query', 'page'], state) || 1;
  const offset = (currentPage - 1) * perPage;
  return {
    currentPage,
    query: {
      perPage,
      offset,
    },
    // A function so we can call it later after
    // we have received the response headers we need.
    state: totalItems => ({
      currentPage,
      offset,
      perPage,
      totalItems: parseInt(totalItems, 10),
    }),
  };
};

export default toPaginationData;
