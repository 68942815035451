import classNames from 'classnames';
import * as R from 'ramda';
import Link from 'components/general/Link/Link';
import React from 'react';
import track from 'react-tracking';
import { compose, branch, withHandlers } from 'recompose';
import LinkProps from 'utils/ts/LinkProps';

const Button: React.SFC<ButtonPropDef> = (props) => {
  const {
    types,
    classes,
    children,
    to,
    className,
    type,
    btnRef,
    onBlur,
    disabled,
    tracking,
    onClick,
    ...args
  } = props;

  const btnClasses = classNames(
    'btn',
    className,
    types.map(type => `btn--${type}`),
    { 'btn--disabled': disabled },
    ...classes,
  );

  const Wrapper = to
    ? (disabled ? 'span' : Link)
    : 'button';

  return (
    <Wrapper 
      className={btnClasses}
      to={to}
      {...args}
      type={type}
      ref={btnRef}
      onClick={onClick}
      onBlur={onBlur}
      disabled={disabled}
    >
      {children}
    </Wrapper>
  );
};

Button.defaultProps = {
  types: [],
  classes: [],
};

interface ButtonPropDef {
  onClick?(e: { [x: string]: any });
  onBlur?();
  to?: LinkProps;
  types?: string[];
  classes?: string[];
  className?: string;
  type?: string;
  btnRef?(any);
  disabled?: boolean;
  event?: string;
  // Provided by react-tracking
  tracking?: object;
}

export default compose(
  track(),
  branch(
    R.has('event'),
    withHandlers({
      onClick: ({ onClick, tracking, event }) => () => {
        tracking.trackEvent({ event });
        if (onClick) onClick();
      },
    }),
  ),
)(Button);
