import * as R from 'ramda';
import api from 'server/api';
import reduxFetch from 'utils/reduxFetch';
import getSiteQuery from 'selectors/getSiteQuery';
import getCustomerID from 'selectors/getCustomerID';
import { actions } from './ducks/users.duck';
import customerThunk from './../../thunk';

const route = async (dispatch, getState) => {
  const state = getState();
  const id = getCustomerID(state);
  const values = getSiteQuery(state);

  const usersThunk = reduxFetch({
    fetch: api('user', 'get'),
    perPage: 10,
    filters: {
      customerId: id,
      ...values,
      sort: {
        by: R.prop('sort', values),
        order: R.prop('order', values),
      },
    },
    actions,
  });

  return Promise.all([
    dispatch(customerThunk),
    dispatch(usersThunk),
  ]);
};

export default route;
