import * as R from 'ramda';
import React from 'react';
import { connect } from 'react-redux';
import { withProps } from 'recompose';
import getUserAbility from 'state/selectors/getUserAbility';
import handleRenderProp from 'utils/handleRenderProp';
import RenderPropType from 'utils/ts/RenderPropType';

/**
 * An implementation of Casl React's Can that supports fields.
 * @link https://github.com/stalniy/casl/tree/master/packages/casl-react
 */
const CaslCan: React.SFC<CaslCanDef> = ({
  action = 'read',
  on,
  field,
  feature,
  ability,
  render,
  fallbackRender,
  children,
  invert,
}) => {
  if (!ability) return handleRenderProp(fallbackRender);

  const canAccessResource = on
    ? ability[invert ? 'cannot' : 'can'](action, on, field)
    : true;

  const canUseFeature = feature
    ? ability[invert ? 'cannot' : 'can']('use', feature)
    : true;

  // If we are not allowed access
  if (!canAccessResource || !canUseFeature) {
    return handleRenderProp(fallbackRender);
  }

  // Render using a render prop or nested children
  return render ? handleRenderProp(render) : children;
};

/**
 * Binds the ability prop on Casl's 'Can' component to be an instance
 * created from the user object in the redux store.
 */
const Can: React.SFC<CanPropDef> = connect(R.applySpec({
  ability: getUserAbility,
}))(CaslCan);

/**
 * Prebound Can with invert
 */
export const Cannot: React.SFC<CanPropDef> = withProps({
  invert: true,
})(Can);

interface CanPropDef {
  action: 'update' | 'manage' | 'edit' | 'read' | 'delete' | 'create' | 'use';
  on?: string;
  field?: string;
  feature?: string;
  // Change can to cannot
  invert?: boolean;
  // Render prop
  render?();
  // Opportunity to provide a 'cannot-access' style component
  fallbackRender?: RenderPropType;
}

type CaslCanDef = CanPropDef & {
  // No obvious typings available for Casl
  ability: {
    [x: string]: any,
  };
};

export default Can;

