/**
 * origin
 */
import * as R from 'ramda';
import { createAction, createReducer } from 'redux-act';

/**
 * Actions
 */
export const setOrigin = createAction(
  'SET_ORIGIN',
  origin => ({ origin }),
);

/**
 * Selectors
 */
export const getOrigin = R.prop('origin');

/**
 * Reducer
 */
const origin = createReducer({
  [setOrigin]: (state, payload) => R.prop('origin', payload),
}, null);

/**
 * Full reducer
 */
export default origin;
