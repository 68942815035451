import * as R from 'ramda';
import postForm from 'utils/postForm';
import fetchData, { fetchDataResponse, fetchDataOptions } from 'utils/fetchData';

export type apiReturnType = (
  options: fetchDataOptions,
) => fetchDataResponse;

export const fetchGet = fetchData('GET');
export const fetchPost = fetchData('POST');

// Fetches
export const fetchGeography = fetchGet('geography');
export const fetchForecast = fetchGet('forecast');
export const fetchBulletins = fetchGet('bulletin');
export const fetchArticles = fetchGet('article');
export const fetchAlerts = fetchGet('alert');
export const fetchUser = fetchGet('user/whoami');
export const fetchInvitations = fetchGet('invitation');

const endpoints = {
  user: ['GET', 'DELETE', 'PATCH'],
  'user/whoami': ['GET'],
  geography: ['GET'],
  forecast: ['GET'],
  bulletins: ['GET'],
  article: ['GET'],
  alert: ['GET'],
  page: ['GET'],
  customer: ['GET'],
  invitation: ['GET', 'POST', 'DELETE'],
  'invitation/accept': ['POST'],
  settings: ['GET'],
  preference: ['GET', 'POST', 'PATCH'],
  search: ['GET'],
  subscription: ['GET', 'POST', 'PATCH'],
};

/**
 * A curried instance of {@link fetchData} with the first two arguments
 * satisfied, and our primary means of interacting with the API. Provides
 * warnings if an endpoint/method has not been implemented. Usually for use
 * with {@link reduxFetch} as the `fetch` config prop.
 * @function
 * @param endpoint - The target endpoint, e.g. `alert`.
 * @param method - Request method, `GET` / `POST` etc.
 * @returns {fetchData} {@link fetchData} with first two args satisfied.
 * @throws Error - Throws an error when a method/endpoint doesn't exist.
 * @example
 * reduxFetch(
 *   fetch: api('alert', 'get'),
 *   // ...
 * )
 */
export const api = (() => {
  const func = R.mapObjIndexed(
    (supports, endpoint) => (
      R.pipe(
        R.map((method: string) => ({
          [method.toLowerCase()]: fetchData(method, endpoint),
        })),
        R.mergeAll,
      )(supports)
    ),
    endpoints,
  );

  return (endpoint: string, method: string): apiReturnType => {
    if (R.prop(endpoint, func)) {
      const val: apiReturnType = R.path([endpoint, method], func);
      if (val) return val;
      throw new Error(`
        ${endpoint} does not support method ${method}.
        Add support in server/api.js.
      `);
    } else {
      throw new Error(`
        ${endpoint} endpoint does not exist. Add support in server/api.js.
      `);
    }
  };
})();

// Forms
export const postSignup = postForm('user/signup');
export const postRequestPasswordReset = postForm('user/request-password-reset');
export const postPasswordReset = postForm('user/password-reset');
export const postValidateEmail = postForm('user/validate-email');
export const postInviteNewUser = postForm('invitation');
export const postAcceptInvite = postForm('invitation/accept');
export const postCustomerCreate = postForm('customer');

export default api;
