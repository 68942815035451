/**
 * SiteStickyFlashes
 */
import { connect } from 'react-redux';
import StickyFlashes from 'components/general/StickyFlashes/StickyFlashes';
import { getStickyFlashes } from 'ducks/flashes';

const mapStateToProps = state => ({
  flashes: getStickyFlashes(state),
});

const SiteStickyFlashes = connect(
  mapStateToProps,
)(StickyFlashes);

export default SiteStickyFlashes;
